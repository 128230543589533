<template>
  <div>
    <!-- 查询条件 -->
    <div class="ground-white top">
      <el-form ref="formdata" :model="formdata" inline size="small">
        <el-form-item label="推广ID:" prop="inviterId">
          <el-input
            v-model="formdata.inviterId"
            placeholder="请输入推广ID"
            @input="(val) => (formdata.inviterId = val.replace(/[^\d]/, ''))"
            clearable
            @change="search"
          />
        </el-form-item>
        <el-form-item label="推广码:" prop="inviteCode">
          <el-input
            v-model="formdata.inviteCode"
            placeholder="请输入推广码"
            clearable
            @change="search"
          />
        </el-form-item>
        <el-form-item>
          <el-button plain icon="el-icon-refresh-left" @click="reset"
            >重置
          </el-button>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <div class="ground-white">
      <el-table v-loading="loading" :data="tabledata">
        <el-table-column prop="accountId" label="推广ID" />
        <el-table-column prop="inviteCode" label="推广码" />
        <el-table-column prop="nickName" label="昵称" />
        <el-table-column prop="mobile" label="手机号" />
        <el-table-column prop="roleName" label="角色名称" />
        <el-table-column prop="departmentName" label="部门" />
        <el-table-column label="操作" align="center" min-width="190">
          <template #default="{ row }">
            <el-button
              @click="updateQrcode(row.inviteCode)"
              plain
              :disabled="!row?.inviteCode"
              >刷新推广码</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <el-pagination
        :page-size="pagination.pageSize"
        :current-page="pagination.pageIndex"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formdata: {
        accountId: "",
        inviteCode: "",
      },
      tabledata: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.pagination.pageIndex = pageIndex;
      this.getData();
    },
    getData() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        ...this.formdata,
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
      };
      this.$api.supportApi
        .searchPromotion(params)
        .then((res = {}) => {
          const { code, data = {}, msg } = res;
          if (code === 0) {
            const { content = [], totalElements = 0 } = data;
            this.tabledata = content;
            this.pagination.total = totalElements;
          } else {
            msg && this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.pagination.pageIndex = 1;
      this.getData();
    },
    reset() {
      this.$refs.formdata.resetFields();
      this.search();
    },
    updateQrcode(inviteCode) {
      this.$api.supportApi.refreshGameInviteCode({ inviteCode }).then(() => {
        this.getData();
        this.$message.success("操作成功!");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  margin-bottom: 20px;
}
.el-pagination {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
</style>
