<template>
  <div>
    <el-dialog
      title="封禁记录"
      :visible="true"
      class="Record"
      width="1200px"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :before-close="() => $emit('close')"
    >
      <!-- 查询条件 -->

      <el-form ref="formdata" :model="formdata" inline size="small">
        <el-form-item label="IP/设备号" prop="targetId">
          <el-input
            :placeholder="
              formdata.targetType == '1' ? '请输入IP' : '请输入设备号'
            "
            v-model="formdata.targetId"
            class="targetId"
          >
            <el-select
              v-model="formdata.targetType"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="IP" value="1"></el-option>
              <el-option label="设备号" value="2"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="封禁状态" prop="status">
          <el-select v-model="formdata.status" placeholder="请选择">
            <el-option label="解封" value="0"></el-option>
            <el-option label="封禁" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button plain icon="el-icon-refresh-left" @click="reset"
            >重置
          </el-button>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addVisible = true"
            >新增封禁
          </el-button>
        </el-form-item>
      </el-form>

      <!-- 表格 -->
      <el-table border v-loading="loading" :data="tabledata" height="480px">
        <el-table-column prop="targetType" label="封禁目标类型">
          <template slot-scope="scope">
            {{ targetTypeDesc[scope.row.targetType] }}
          </template>
        </el-table-column>
        <el-table-column prop="targetId" label="IP/设备号">
          <template slot-scope="scope">
            <div
              :style="{ color: scope.row.status == 1 ? '#909399' : '#67c23a' }"
            >
              {{ scope.row.targetId }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'info' : 'success'">{{
              statusDesc[scope.row.status]
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="scope.row.status == 0"
              slot="reference"
              @click="handleBan(scope.row)"
              >解封</el-button
            >
            <span> / </span>
            <el-button
              type="text"
              :disabled="scope.row.status == 1"
              @click="handleBan(scope.row)"
              >封禁</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="operatorName" label="操作人" />
        <el-table-column prop="remark" label="备注" />
      </el-table>
      <!-- 分页器 -->
      <Pagination
        layout="prev, pager, next, jumper"
        :page-size="pagination.pageSize"
        :current-page="pagination.pageIndex"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      />
    </el-dialog>
    <!-- 新增 -->
    <addBan v-if="addVisible" @close="addVisible = false" @submit="search" />
  </div>
</template>

<script>
import addBan from "./addBan.vue";
// 状态 0 解禁 1 封禁中
const statusDesc = {
  0: "未封禁",
  1: "封禁中",
};
// targetType 封禁目标类型 1 IP 2 设备
const targetTypeDesc = {
  1: "IP",
  2: "设备",
};
export default {
  components: {
    addBan,
  },
  data() {
    return {
      statusDesc,
      targetTypeDesc,
      formdata: {
        targetType: "1",
        targetId: "",
        status: "",
      },
      tabledata: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      addVisible: false,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.pagination.pageIndex = pageIndex;
      this.getData();
    },
    getData() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        ...this.formdata,
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
      };
      // 没有
      if (!params.targetId) {
        params.targetType = "";
      }
      this.$api.supportApi
        .getBanRecord(params)
        .then((res = {}) => {
          const { code, data = {}, msg } = res;
          if (code === 0) {
            const { content = [], totalElements = 0 } = data;
            this.tabledata = content || [];
            this.pagination.total = totalElements;
          } else {
            msg && this.$message.error(msg);
          }
        })
        .catch(() => {
          this.tabledata = [
            {
              id: 1,
              targetId: "127.0.0.1",
              targetType: 1,
              status: 1,
              remark: "无",
              operator: 111,
              operatorName: "admin",
            },
            {
              id: 2,
              targetId: "127.0.0.1",
              targetType: 1,
              status: 0,
              remark: "无",
              operator: 111,
              operatorName: "admin",
            },
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.pagination.pageIndex = 1;
      this.getData();
    },
    reset() {
      this.$refs.formdata.resetFields();
      this.formdata.targetType = "1";
      this.search();
    },
    addClosed() {
      this.$refs.addFormRef.resetFields();
    },
    handleBan(row) {
      const params = {
        id: row.id,
        status: row.status == 1 ? 0 : 1,
      };
      this.loading = true;
      this.$api.supportApi
        .updateBanRecord(params)
        .then((res = {}) => {
          const { code, msg } = res;
          console.log("🚀 ~ .then ~ code:", code);
          this.loading = false;
          if (code === 0) {
            this.$message.success("操作成功");
            this.getData();
          } else {
            msg && this.$message.error(msg);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .targetId .el-select .el-input {
  width: 90px;
}

::v-deep .el-table__header th {
  background: #f7f9fd;
  font-weight: bold;
  height: 40px;
  padding: 0;
  color: #95a3bb;
}

.el-pagination {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
</style>
