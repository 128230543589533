<template>
  <div class="show_game_icon">
    <span v-if="gameName && opPlatform === 'Android'"
      ><img src="@/assets/img/icon-android.png" alt="安卓"
    /></span>
    <span v-if="gameName && opPlatform.toLowerCase() === 'ios'"
      ><img src="@/assets/img/icon-apple.png" alt="iOS"
    /></span>
    <span>{{ gameName }}</span>
  </div>
</template>
<script>
export default {
  props: ["gameName", "opPlatform"],
  mounted() {
    // console.log("this", this);
  },
};
</script>
<style lang="less" scoped>
.show_game_icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    &:nth-of-type(1) {
      padding-right: 6px;
    }
  }

  img {
    width: 20px;
  }
}
</style>
