<template>
  <div class="singleUpload">
    <div class="upload-loading" v-loading="true" v-if="vLoading"></div>
    <el-upload
      class="avatar-upload"
      :action="$api.gameApi.uploadResourc()"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :headers="headersObj"
      :before-upload="beforeUpload"
      :limit="1"
      ref="upload"
      :file-list="fileList"
      :on-progress="handleProgress"
    >
      <template v-if="modelValue">
        <img v-if="modelValue && !isVideo" :src="modelValue" class="imgs" />
        <!-- 展示第一帧，无法播放 -->
        <video
          v-if="modelValue && isVideo"
          :src="modelValue"
          class="imgs"
        ></video>
        <div class="file-operate" v-if="modelValue">
          <i
            class="el-icon-delete"
            @click.stop="$emit('update:modelValue', '')"
          ></i>
        </div>
      </template>
      <i v-else class="el-icon-plus avatar-upload-icon"></i>
      <div v-if="!isVideo" slot="tip" class="el-upload__tip">
        格式jpg/png、大小不超过10MB
      </div>
      <div v-else slot="tip" class="el-upload__tip">
        格式mp4、大小不超过100MB
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "loginPage",
  // 接收v-model 双向绑定
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  data() {
    return {
      vLoading: false,
      timerLoading: null,
      fileList: [],
      picUrl: "",
      headersObj: {
        Authorization: localStorage.getItem("token"),
      },
    };
  },
  methods: {
    beforeUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";
      const isMp4 = file.type === "video/mp4";

      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;
      const is100M = file.size / 1024 / 1024 < 100;
      if (this.isVideo) {
        //限制文件上传类型
        if (!isMp4) {
          this.$message.error("文件只能是 mp4 格式!");
          return false;
        }

        //限制文件上传大小
        if (!is100M) {
          this.$message.error("文件大小不能超过 100MB!");
          return false;
        }
        return true;
      }

      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("文件只能是 png,jpg,jpeg 格式!");
        return false;
      }

      //限制文件上传大小
      if (!is10M) {
        this.$message.error("文件大小不能超过 10MB!");
        return false;
      }

      return true;
    },
    handleAvatarSuccess(res, file) {
      this.fileList = [];
      if (res.code === 0) {
        const picUrl = res.data.uploadUrl || URL.createObjectURL(file.raw);
        this.$emit("update:modelValue", picUrl);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleProgress() {
      this.vLoading = true;
      if (this.timerLoading) {
        clearTimeout(this.timerLoading);
      }
      this.timerLoading = setTimeout(() => {
        this.vLoading = false;
      }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
@avatar-upload-width: 100px;
.singleUpload {
  position: relative;
  .upload-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: @avatar-upload-width + 2px;
    height: @avatar-upload-width + 2px;
  }
  /deep/ .avatar-upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-upload .el-upload:hover {
    border-color: #409eff;
    .file-operate {
      display: block;
    }
  }
  /deep/.avatar-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: @avatar-upload-width;
    height: @avatar-upload-width;
    line-height: @avatar-upload-width;
    text-align: center;
  }
  /deep/.imgs {
    width: @avatar-upload-width;
    height: @avatar-upload-width;
    display: block;
    object-fit: contain;
  }
  .file-operate {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    cursor: pointer;
    i {
      color: #fff;
      font-size: 16px;
    }
  }
  .el-upload__tip {
    font-size: 12px;
    color: #a33;
    margin-top: -10px;
    line-height: 18px;
  }
}
</style>
