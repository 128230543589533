<template>
  <div class="qr-code">
    <el-dialog
      title="预览"
      :visible.sync="visible"
      width="30%"
      center
      @close="$emit('close')"
    >
      <img class="qrcode" :src="QRImgUrl" alt="" />
      <div class="btn-container">
        <el-button
          size="mini"
          class="tag-read"
          style="background: rgba(187, 200, 220, 0.3)"
          :data-clipboard-text="getShareUrl()"
          @click="copy"
        >
          复制预览链接
        </el-button>
        <el-button
          size="mini"
          style="background: rgba(187, 200, 220, 0.3)"
          @click="openView"
        >
          打开预览页面
        </el-button>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('close')">取 消</el-button>
        <el-button type="primary" @click="$emit('close')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcode";
import Clipboard from "clipboard";

export default {
  name: "loginPage",
  props: {
    data: {
      default: () => ({}),
    },
  },
  data() {
    return {
      QRImgUrl: "",

      visible: true,
    };
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    getShareUrl() {
      const isTest = window.location.href.indexOf("/test") !== -1;
      return `https://web.zhongyiyf.com${
        isTest ? "/test" : ""
      }/#/share/promotion?gameId=${this.data.id}`;
    },
    creatQrCode() {
      const that = this;
      const data = this.getShareUrl();
      QRCode.toDataURL(data, function (err, url) {
        that.QRImgUrl = url;
      });
    },
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    openView() {
      window.open(this.getShareUrl());
    },
  },
};
</script>
<style lang="less" scoped>
.qrcode {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.btn-container {
  display: flex;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  box-sizing: border-box;
  overflow: auto;
}

@media (max-width: 440px) {
  ::v-deep .el-dialog {
    width: 350px !important;
  }
}
</style>
