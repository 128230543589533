import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const organizationApi = {
  //获取页面基础配置
  treeDetail(params) {
    return axios.post(`${base.sq}/resource/queryMenuInterface`, params);
  },
  // 获取公会列表
  getUnionList(params) {
    return axios.post(`${base.sq}/union/query`, params);
  },
  // 创建公会
  createUnion(params) {
    return axios.post(`${base.sq}/union/create`, params);
  },
  // 编辑公会
  updateUnion(params) {
    return axios.post(`${base.sq}/union/update`, params);
  },
  // 冻结：解冻公会
  changeUnionStatus(params) {
    return axios.post(`${base.sq}/union/status`, params);
  },
  // 重置密码
  resetPassword(params) {
    return axios.post(`${base.sq}/union/password`, params);
  },
  // 删除公会
  deleteUnion(params) {
    return axios.post(`${base.sq}/union/delete`, params);
  },
  // ----------------   人员结构    ----------------
  // 创建部门
  createDepartment(params) {
    return axios.post(`${base.sq}/department/create`, params);
  },
  // 编辑部门
  updateDepartment(params) {
    return axios.post(`${base.sq}/department/update`, params);
  },

  deleteDepartment(params) {
    return axios.post(`${base.sq}/department/delete`, params);
  },
  // 查询部门
  queryDepartment(params) {
    return axios.post(`${base.sq}/department/query`, params);
  },
  // 查询人员列表
  queryMember(params) {
    return axios.post(`${base.sq}/member/query`, params);
  },
  // 冻结：解冻人员
  changeMemberStatus(params) {
    return axios.post(`${base.sq}/member/status`, params);
  },
  // 重置人员密码
  resetMemberPassword(params) {
    return axios.post(`${base.sq}/member/password`, params);
  },
  // 删除人员
  deleteMember(params) {
    return axios.post(`${base.sq}/member/delete`, params);
  },
  // 创建人员
  createMember(params) {
    return axios.post(`${base.sq}/member/createMember`, params);
  },
  // 编辑人员
  updateMember(params) {
    return axios.post(`${base.sq}/member/update`, params);
  },
  // ----------------   人员权限    ----------------
  // 获取权限列表
  getPermissionTree(params) {
    return axios.post(`${base.sq}/resource/queryResource`, params);
  },
  // 查询角色
  queryRole(params) {
    return axios.post(`${base.sq}/role/query`, params);
  },
  // 创建角色
  createRole(params) {
    return axios.post(`${base.sq}/role/create`, params);
  },
  // 编辑角色
  updateRole(params) {
    return axios.post(`${base.sq}/role/update`, params);
  },
  // 冻结：解冻人员
  changeRoleStatus(params) {
    return axios.post(`${base.sq}/role/status`, params);
  },
  // 删除人员
  deleteRole(params) {
    return axios.post(`${base.sq}/role/delete`, params);
  },
  // 获取角色列表
  queryRoleList(params) {
    return axios.post(`${base.sq}/role/queryList`, params);
  },
  // ----------------   人员权限    ----------------
  // 查询角色人员
  queryRoleMember(params) {
    return axios.post(`${base.sq}/member/queryMember`, params);
  },
  // 新建角色人员
  createRoleMember(params) {
    return axios.post(`${base.sq}/member/createAdmin`, params);
  },
  // 更新角色人员权限
  updateRoleMember(params) {
    return axios.post(`${base.sq}/member/setResource`, params);
  },
  // 重置角色人员密码
  resetRoleMember(params) {
    return axios.post(`${base.sq}/member/setResource`, params);
  },
  batchFrozenMember(params) {
    return axios.post(`${base.sq}/role/batchStatusList`, params);
  },
  // ----------------   开服计划    ----------------
  // 查询开服计划
  queryAreaPlan(params) {
    return axios.post(`${base.sq}/gameService/query`, params);
  },
  // 查询开服计划1
  queryService(params) {
    return axios.post(`${base.sq}/gameService/queryService`, params);
  },
  // 查询开服计划
  queryUnionAreaPlan(params) {
    return axios.post(`${base.sq}/gameService/query/union`, params);
  },
  // 新建开服计划
  createAreaPlan(params) {
    return axios.post(`${base.sq}/gameService/create`, params);
  },
  // 更新开服计划权限
  updateAreaPlan(params) {
    return axios.post(`${base.sq}/gameService/update`, params);
  },
  // 获取游戏推广页配置信息
  getInvitePageConfigInfo(params) {
    return axios.get(
      `${base.sq}/game/getInvitePageConfigInfo?gameId=${params.gameId}`
    );
  },
  // 设置游戏推广页配置信息
  setInvitePageConfigInfo(params) {
    return axios.post(`${base.sq}/game/setInvitePageConfigInfo`, params);
  },
  // 查询迁移记录
  queryChangeLog(params) {
    return axios.post(`${base.sq}/gameTransfer/query`, params);
  },
  // 查询迁移记录v2
  queryChangeLogV2(params) {
    return axios.post(`${base.sq}/v2/gameTransfer/query`, params);
  },
  // 游戏资源操作人列表查询
  queryOperator(params) {
    return axios.post(`${base.sq}/v1/gameTransfer/operator`, params);
  },
  // 新增迁移记录
  AddChangeLog(params) {
    return axios.post(`${base.sq}/gameTransfer/create`, params);
  },
  // 查询推广员
  queryStaff(params) {
    return axios.post(`${base.sq}/member/list`, params);
  },
  // 获取游戏角色
  queryGameRole(params) {
    return axios.post(`${base.sq}/gameRole/api/getGameRoleInfo`, params);
  },
  // 获取有效角色
  queryUsefulRole(params) {
    return axios.post(`${base.sq}/validRoleConfig/query`, params);
  },
  // 新增有效角色
  addUsefulRole(params) {
    return axios.post(`${base.sq}/validRoleConfig/create`, params);
  },
  // 更新有效角色
  updateUsefulRole(params) {
    return axios.post(`${base.sq}/validRoleConfig/update`, params);
  },
  // 获取有效角色设置列表-后管
  getUsefulRoleList(params) {
    return axios.post(`${base.sq}/validRoleConfig/statis`, params);
  },
  // 虚拟充值金额配置查询-公会
  getAmountList(params) {
    return axios.post(`${base.sq}/recharge/amount/config/list`, params);
  },
  // 虚拟充值-公会
  rechargeHandle(params) {
    return axios.post(`${base.sq}/recharge/apply`, params);
  },
  // 充值角色 - 充值调整
  virTualUnionSum(params) {
    return axios.post(`${base.sq}/recharge/virtual/union/sum`, params);
  },
  // 上传合同接口
  uploadContract() {
    return `${base.sq}/gameUpload/upload`;
  },
  uploadOss(params) {
    return axios.post(`${base.sq}/account/upload`, params);
  },
  // 查询游戏渠道
  getChannel(params) {
    return axios.post(`${base.sq}/gameChannel/getChannel`, params);
  },
  // 新增游戏渠道
  addChannel(params) {
    return axios.post(`${base.sq}/gameChannel/addChannel`, params);
  },
  // 更新游戏渠道
  updateChannel(params) {
    return axios.post(`${base.sq}/gameChannel/updateChannel`, params);
  },
};

export default organizationApi;
