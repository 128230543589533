import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const supportApi = {
  // 资源管理
  resourceManage(params) {
    return axios.post(`${base.sq}/resource/management`, params);
  },
  // 转移记录
  gameTransfer(params) {
    return axios.post(`${base.sq}/gameTransfer/query`, params);
  },
  // 转移记录v2
  gameTransferV2(params) {
    return axios.post(`${base.sq}/v2/gameTransfer/query`, params);
  },
  // 游戏资源操作人列表查询
  queryOperator(params) {
    return axios.post(`${base.sq}/v1/gameTransfer/operator`, params);
  },
  //获取导航
  tree(params) {
    return axios.post(`${base.sq}/resource/queryMenu`, params);
  },
  //获取页面基础配置
  treeDetail(params) {
    return axios.post(`${base.sq}/resource/queryMenuInterface`, params);
  },
  //修改账户信息
  updateAccountInfo(params) {
    return axios.post(`${base.sq}/account/updateInfo`, params);
  },
  //查询账户信息
  queryAccountInfo(params) {
    return axios.post(`${base.sq}/account/query`, params);
  },
  //修改密码
  updatePassword(params) {
    return axios.post(`${base.sq}/account/updatePassword`, params);
  },

  // 账号管理 - 查询列表
  queryAccountList(params) {
    return axios.post(`${base.sq}/game/account/management`, params);
  },

  // 资源账号 - 查询列表
  queryResourceAccountList(params) {
    return axios.post(`${base.sq}/resource/account`, params);
  },

  // 异常订单 - 查询列表
  queryAbnormalOrder(params) {
    return axios.post(`${base.sq}/order/abnormalOrder`, params);
  },

  // 个人记录 - 查询
  queryOpLog(params) {
    return axios.post(`${base.sq}/account/opLog`, params);
  },

  // 玩家转移-账号转移-角色转移
  createGameTransfer(params) {
    return axios.post(`${base.sq}/gameTransfer/create`, params);
  },

  // 玩家转移-获取推广员
  getGameInviter(params) {
    return axios.post(`${base.sq}/game/inviter`, params);
  },

  // 冻结人员
  frozenMember(params) {
    return axios.post(`${base.sq}/resource/account/frozenStatus`, params);
  },

  frozenMemberV2(params) {
    return axios.post(`${base.sq}/v2/resource/account/frozenStatus`, params);
  },

  // 异常订单--重新发货
  redeliveryOrder(params) {
    return axios.post(`${base.sq}/order/redelivery`, params);
  },

  // 获取游戏列表
  getGameList(params) {
    return axios.post(`${base.sq}/game/list`, params);
  },

  // 获取区服列表
  getGameServiceList(params) {
    return axios.post(`${base.sq}/game/gameService`, params);
  },
  // 人员管理-添加人员
  createMember(params) {
    return axios.post(`${base.sq}/member/createMember`, params);
  },
  // 人员管理-编辑人员
  editMember(params) {
    return axios.post(`${base.sq}/member/update`, params);
  },
  // 人员管理-设置人员状态
  setMemberStatus(params) {
    return axios.post(`${base.sq}/member/status`, params);
  },
  // 人员管理-删除
  deleteMember(params) {
    return axios.post(`${base.sq}/member/delete`, params);
  },

  // 个人记录-查询资源账号配置
  queryAccountConfig(params) {
    return axios.post(`${base.sq}/config/query`, params);
  },
  // 个人记录-更新资源账号配置
  updateAccountConfig(params) {
    return axios.post(`${base.sq}/config/update`, params);
  },
  // 推广员
  queryInviter(params) {
    return axios.post(`${base.sq}/member/queryInviter`, params);
  },
  // 获取推广员列表
  allRangeQuery(params) {
    return axios.post(`${base.sq}/member/allRangeQuery`, params);
  },

  searchPromotion(params) {
    return axios.post(`${base.sq}/game/queryGameInviter`, params);
  },

  refreshGameInviteCode(params) {
    return axios.post(`${base.sq}/game/refreshGameInviteCode`, params);
  },
  // 记录查询
  getAccountLoginRecord(params) {
    return axios.post(`${base.sq}/game/account/getAccountLoginRecord`, params);
  },
  // 查询封号记录
  getBanRecord(params) {
    return axios.post(`${base.sq}/ban/queryBanRecord`, params);
  },
  // 新增封号记录
  createBanRecord(params) {
    return axios.post(`${base.sq}/ban/createBanRecord`, params);
  },
  // 更新封号记录
  updateBanRecord(params) {
    return axios.post(`${base.sq}/ban/updateBanRecord`, params);
  },
};

export default supportApi;
