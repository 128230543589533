<template>
  <div>
    <div class="title">
      <span class="tab-title">登录记录</span>
      <el-button type="primary" size="mini" @click="exportExcel"
        >导出</el-button
      >
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      height="300"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="gameRoleName"
        label="角色名"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="loginTime" label="最近登录">
      </el-table-column>
      <el-table-column align="center" prop="logoutTime" label="下线时间">
      </el-table-column>
      <el-table-column align="center" prop="ip" label="登录IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.ip }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="device" label="登录设备">
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next"
      :page-size="20"
      :current-page.sync="midForm.pageIndex"
      @current-change="handleCurrentChange"
      :total="midForm.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      midForm: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      propParams: {},
      tableData: [],
    };
  },

  mounted() {},
  methods: {
    refresh(params = {}) {
      this.propParams = params;
      this.getMid();
    },
    getMid() {
      this.$api.dataApi
        .gameRoleLoginList({
          ...this.midForm,
          ...this.propParams,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            // this.midForm.pageIndex = res.data.pageIndex;
            this.midForm.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.midForm.pageIndex = val;
      this.getMid();
    },
    exportExcel() {
      // 导出Excel
      this.$api.dataApi
        .exportGameRoleLoginList({
          ...this.midForm,
          ...this.propParams,
        })
        .then((res) => {
          // 导出Excel
          this.$exportExcel(
            res,
            `充值记录数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
  .tab-title {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
