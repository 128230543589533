<template>
  <div class="promotion">
    <el-dialog
      title="编辑推广"
      :visible.sync="visible"
      @close="$emit('close')"
      width="1200px"
    >
      <el-form
        :inline="true"
        ref="gameForm"
        :model="gameForm"
        label-width="80px"
      >
        <el-form-item prop="logo" label="游戏logo">
          <singleUpload v-model="gameForm.logo"></singleUpload>
        </el-form-item>
        <br />
        <el-form-item prop="title" label="游戏名称">
          <el-input v-model="gameForm.title" placeholder="游戏名称"></el-input>
        </el-form-item>
        <el-form-item prop="content" label="游戏介绍">
          <el-input
            v-model="gameForm.content"
            placeholder="游戏介绍"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="iosDownloadUrl"
          label="IOS下载地址"
          label-width="120px"
        >
          <el-input
            v-model="gameForm.iosDownloadUrl"
            placeholder="IOS下载地址"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item prop="topBg" label="top背景">
          <singleUpload v-model="gameForm.topBg"></singleUpload>
        </el-form-item>
        <br />
        <el-form-item prop="publicizeImg" label="宣传素材">
          <singleUpload v-model="gameForm.publicizeImg"></singleUpload>
        </el-form-item>
        <el-form-item prop="publicizeVideo" label="宣传视频">
          <singleUpload
            v-model="gameForm.publicizeVideo"
            isVideo
          ></singleUpload>
        </el-form-item>
        <br />
        <el-form-item prop="isSecond" label="游戏特色">
          <el-switch v-model="gameForm.isSecond"></el-switch>
        </el-form-item>
        <el-form-item prop="secondTitle" label="标题" v-if="gameForm.isSecond">
          <el-input
            v-model="gameForm.secondTitle"
            placeholder="标题"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="secondContent"
          label="说明"
          v-if="gameForm.isSecond"
        >
          <el-input
            v-model="gameForm.secondContent"
            placeholder="说明"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="secondType"
          label="轮播类型"
          v-if="gameForm.isSecond"
        >
          <el-select v-model="gameForm.secondType">
            <el-option
              v-for="item in secondTypeOptopions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <br />
        <el-form-item prop="secondBg" label="背景图" v-if="gameForm.isSecond">
          <singleUpload v-model="gameForm.secondBg"></singleUpload>
        </el-form-item>
        <el-form-item prop="secondList" label="轮播图" v-if="gameForm.isSecond">
          <multipleUpload
            v-model="gameForm.secondList"
            :rotate="gameForm.secondType"
            :min="gameForm.secondType === '1' ? 2 : 1"
          ></multipleUpload>
        </el-form-item>
        <br />
        <el-form-item prop="isThree" label="职业介绍">
          <el-switch v-model="gameForm.isThree"></el-switch>
        </el-form-item>
        <el-form-item prop="threeTitle" label="标题" v-if="gameForm.isThree">
          <el-input v-model="gameForm.threeTitle" placeholder="标题"></el-input>
        </el-form-item>

        <br />
        <el-form-item prop="threeFourBg" label="背景图" v-if="gameForm.isThree">
          <singleUpload v-model="gameForm.threeFourBg"></singleUpload>
        </el-form-item>
        <el-form-item prop="threeList" label="轮播图" v-if="gameForm.isThree">
          <multipleUpload
            v-model="gameForm.threeList"
            :rotate="3"
          ></multipleUpload>
        </el-form-item>
        <br />

        <br />
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('close')">取 消</el-button>
        <el-button type="primary" @click="setGame">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import singleUpload from "./singleUpload";
import multipleUpload from "./multipleUpload";
export default {
  name: "loginPage",
  components: {
    singleUpload,
    multipleUpload,
  },
  props: {
    gameId: {
      default: null,
    },
  },
  data() {
    return {
      visible: true,
      fileList: [],
      headersObj: {
        Authorization: localStorage.getItem("token"),
      },
      gameForm: {
        iosDownloadUrl: "",
        title: "",
        content: "",
        topBg: "",
        publicizeImg: "",
        publicizeVideo: "",

        isSecond: false,
        secondBg: "",
        secondTitle: "",
        secondContent: "",
        secondList: [],
        secondType: "1",

        isThree: false,
        threeTitle: "",
        threeFourBg: "",
        threeList: [],
      },
      // 竖直图至少两个
      secondVerticalMin: 2,
      secondTypeOptopions: [
        {
          value: "1",
          label: "竖版",
        },
        {
          value: "2",
          label: "横版",
        },
      ],
    };
  },
  methods: {
    init() {
      this.$api.organizationApi
        .getInvitePageConfigInfo({
          gameId: this.gameId,
        })
        .then((res) => {
          let { data } = res;
          try {
            let data2 = JSON.parse(data);
            if (typeof data2 === "object") {
              this.gameForm = {
                ...this.gameForm,
                ...data2,
              };
              // 刷新gameForm
              this.gameForm = JSON.parse(JSON.stringify(this.gameForm));
              this.$set(
                this.gameForm,
                "secondList",
                this.gameForm.secondList.map((item) => item)
              );
            }
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setGame() {
      // 判断 竖版 至少两张图
      if (
        this.gameForm.secondType === "1" &&
        this.secondVerticalMin > this.gameForm.secondList.length
      ) {
        this.$message.error("请上传至少" + this.secondVerticalMin + "个竖版图");
        return;
      }
      let params = {
        id: this.gameId,
        pageInfoConfig: JSON.stringify(this.gameForm),
      };
      this.$api.organizationApi
        .setInvitePageConfigInfo(params)
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.$emit("close");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.promotion {
  /deep/.el-form-item__content {
    min-width: 183px;
  }
}
</style>
