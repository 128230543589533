<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          clearable
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item prop="gameId" label="">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏名称"
        >
          <el-option
            v-for="item in gameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="queryAccountType" label="">
        <el-select
          v-model="formInline.queryAccountType"
          clearable
          placeholder="请选择账号类型"
        >
          <el-option
            v-for="item in accountTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="formInline.unionId"
          clearable
          placeholder="请选择下级公会"
          @change="unionChange"
        >
          <el-option
            v-for="item in getUnionListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        label=""
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        sty="width: 220px"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />

      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.page.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        游戏数：<span>{{ totalData.gameNum }}</span>
      </p>
      <p>
        推广员数：<span>{{ totalData.inviterAccountNum }}</span>
      </p>
      <p>
        游戏账号数：<span>{{ totalData.gameAccountNum }}</span>
      </p>
      <p>
        角色创建数：<span>{{ totalData.roleNum }}</span>
      </p>
      <p>
        充值角色数：<span>{{ totalData.rechargeRoleNum }}</span>
      </p>
      <p>
        充值总额：<span>{{ totalData.rechargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="unionName" label="工会归属">
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
      </el-table-column>
      <el-table-column align="center" prop="roleNum" label="角色数">
        <template slot-scope="scope">
          <div class="badge">
            <span>{{ scope.row.roleNum }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏">
      </el-table-column>
      <el-table-column align="center" prop="registerIp" label="创建IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.registerIp }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="device" label="设备信息">
      </el-table-column>
      <el-table-column align="center" prop="lastLoginTime" label="最近登录时间">
      </el-table-column>
      <el-table-column align="center" prop="" label="详情" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleClick(scope.row)"
            >角色明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("gameAccountSecond");
export default {
  data() {
    return {
      gameListData: [],
      getUnionListData: [],
      staffListData: [],
      loading: false,
      formInline: {
        gameAccountId: null,
        gameId: null,
        unionId: null,
        inviterAccountIdList: [],
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
        queryAccountType: null,
      },
      tableData: [],
      totalData: {},
      accountTypes: [
        {
          label: "当日注册",
          value: 1,
        },
        {
          label: "当日登录",
          value: 2,
        },
        {
          label: "一天未登录",
          value: 3,
        },
        {
          label: "三天未登录",
          value: 4,
        },
      ],
    };
  },
  mounted() {
    // 查询是否设置参数
    if (curdParams.getShow) {
      curdParams.setShow(false);
      let params = curdParams.getParams;
      if (params) {
        this.formInline = { ...this.formInline, ...params };
      }
    }
    this.getGameList();
    this.getUnionList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    getParams() {
      return { ...this.formInline, ...this.formInline.page };
    },
    getUnionList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getUnionListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    unionChange() {
      this.getAllInviter({ unionId: this.formInline.unionId });
      this.formInline.inviterAccountIdList = [];
    },
    exportList() {
      this.formInline.page.pageSize = 50000;
      this.$api.dataApi.gameAccountExportV1(this.getParams()).then((res) => {
        this.formInline.pageSize = 20;
        // 导出Excel
        this.$exportExcel(
          res,
          `已出数据_${this.$moment().format(
            "YYYY-MM-DD"
          )}_${this.$moment().unix()}.xlsx`
        );
      });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .gameAccountTotalV1(this.getParams())
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getList() {
      // 获取公会列表
      let params = this.getParams();
      curdParams.setParams(params);
      this.$api.dataApi
        .gameAccountQueryV1(params)
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      this.$router.push({
        path: "/data/bm/game/account/detail",
        query: {
          gameAccountId: e.gameAccountId,
          gameId: e.gameId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
.role_mark {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .role_mark_icon {
    position: relative;
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #fff;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border: 1px solid #b0b0b0;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: 1px solid #b0b0b0;
    }
  }

  .role_mark_icon.no_line {
    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      // border: 1px solid #b0b0b0;
      border: none;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: none;
    }
  }
  .opt_panel {
    position: absolute;
    z-index: 200;
    top: -30px;
    border: 1px solid #ddd;
    width: 240px;
    padding: 20px;
  }
}
.role_bd {
  display: flex;
  justify-content: flex-start;

  span {
    position: relative;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #d1d1d1;
    cursor: pointer;

    &:nth-of-type(1) {
      border-color: #fb5353;
    }
    &:nth-of-type(2) {
      border-color: #f49207;
    }
    &:nth-of-type(3) {
      border-color: #888;
    }
    &:nth-of-type(4) {
      border-color: #fff;
      &::before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 6px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;

  span {
    &:nth-of-type(2) {
      width: 28px;
      height: 28px;
      line-height: 28px;
      background: rgb(205, 253, 221);
      color: #0cd13a;
      border-radius: 28px;
    }
  }
}

.title-t {
  margin: 25px 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15%;
  padding-left: 15px;
  background: #eff5ff;
}
</style>
