<template>
  <div class="game-apply">
    <!-- top -->
    <div class="top-container ground-white flex">
      <div class="title">今日开服</div>
      <div class="box">
        <div class="bar" ref="bar" v-if="areaList.length">
          <div v-for="item in areaList" class="item" :key="item.id">
            <span>{{
              $moment(item.serviceTime).format("YYYY-MM-DD HH:mm").split(" ")[1]
            }}</span>
            <span style="margin: 0 6px">{{ item.gameName }}</span>
            <span>{{ item.serviceName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- bottom -->
    <div class="bottom-container ground-white">
      <el-radio-group
        style="margin-bottom: 10px"
        size="small"
        v-model="formInline.status"
        @input="changeList"
      >
        <el-radio-button :label="0">正常推广</el-radio-button>
        <el-radio-button :label="1">游戏下架</el-radio-button>
      </el-radio-group>
      <el-form :inline="true" :model="formInline">
        <el-form-item label="区服类型">
          <el-input
            v-model="formInline.gameArea"
            placeholder="区服类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名">
          <el-input
            v-model="formInline.gameName"
            placeholder="游戏名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              formInline.pageIndex = 1;
              getList();
            "
            >查询</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" border>
        <!-- 游戏图标 -->
        <el-table-column label="游戏图标" align="center" min-width="120">
          <template #default="{ row }">
            <img :src="row.gamePic" class="game-icon" alt="游戏图标" />
          </template>
        </el-table-column>

        <!-- 游戏名称 -->
        <el-table-column label="游戏名称" align="center" min-width="250">
          <template #default="{ row }">
            <div class="game-name">
              <p @click="goDetail(row.id)">
                <span>{{ row.gameName }}</span>
                <el-button type="text" class="detail">详情</el-button>
              </p>
            </div>
          </template>
        </el-table-column>

        <!-- 游戏类型 -->
        <el-table-column
          label="游戏类型"
          align="center"
          prop="gameType"
          min-width="250"
        >
        </el-table-column>

        <!-- 游戏区域 -->
        <el-table-column
          label="游戏区域"
          align="center"
          prop="gameArea"
          min-width="250"
        >
        </el-table-column>

        <!-- 苹果状态 -->
        <el-table-column
          label="苹果状态"
          align="center"
          v-if="formInline.status === 0"
          min-width="200"
        >
          <template>
            <p>
              <img
                src="@/assets/img/icon-apple.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              {{ "-" }}
            </p>
          </template>
        </el-table-column>

        <!-- 安卓分包状态 -->
        <el-table-column
          label="安卓分包状态"
          align="center"
          v-if="formInline.status === 0"
          min-width="200"
        >
          <template #default="{ row }">
            <p>
              <img
                src="@/assets/img/icon-android.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              <span v-if="row.packStatus === PACKAGED">
                <a style="color: #2d8cf0 !important" :href="row.packageUrl"
                  >下载游戏</a
                >
              </span>
              <span
                style="color: #f70"
                v-if="!row.packStatus && row.packStatus !== PACKAGE_ING"
                >未分包</span
              >
              <span style="color: #f70" v-if="row.packStatus === PACKAGE_ING"
                >分包中</span
              >
              <span style="color: red" v-if="row.packStatus === PACKAGE_FAIL"
                >分包失败，请重新分包</span
              >
            </p>
          </template>
        </el-table-column>

        <!-- 推广游戏 -->
        <el-table-column
          label="推广游戏"
          align="center"
          v-if="formInline.status === 0"
          min-width="200"
        >
          <template #default="{ row }">
            <el-button
              v-if="row?.packStatus === PACKAGED"
              @mouseenter="creatQrCode(row)"
              type="primary"
              @click="checkCode(row)"
            >
              推广页二维码
            </el-button>
            <el-button
              v-else
              v-loading="row?.packStatus === 0 || row.loading"
              element-loading-spinner="el-icon-loading"
              @click="subContract(row.id)"
              plain
              :disabled="row?.packStatus === 0 || row.loading"
            >
              {{ row?.packStatus === 0 || row.loading ? "分包中" : "分包" }}
            </el-button>
          </template>
        </el-table-column>

        <!-- 刷新推广码 -->
        <el-table-column label="操作" align="center" min-width="190">
          <template #default="{ row }">
            <el-button
              @click="updateQrcode(row.id)"
              plain
              :disabled="row?.packStatus !== PACKAGED"
              >刷新推广码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <p style="width: 100; color: #999; text-align: center">
        注意：当未申请的游戏产生充值时，将以游戏的基础分成比例进行分成
      </p>
      <el-pagination
        style="float: right; margin-top: 8px"
        layout="prev, pager, next, jumper"
        :page-size="20"
        :current-page.sync="formInline.pageIndex"
        @current-change="handleCurrentChange"
        :total="formInline.totalElements"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="currentGame.gameName"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <img class="qrcode" :src="QRImgUrl" alt="" />
      <div class="btn-container">
        <el-button
          size="mini"
          class="tag-read"
          style="background: rgba(187, 200, 220, 0.3)"
          :data-clipboard-text="getShareUrl(currentGame)"
          @click="copy"
        >
          复制推广链接
        </el-button>
        <el-button
          size="mini"
          style="background: rgba(187, 200, 220, 0.3)"
          @click="downloadIamge(currentGame.gameName)"
        >
          保存二维码
        </el-button>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcode";
import Clipboard from "clipboard";

export default {
  name: "loginPage",
  data() {
    return {
      areaList: [],
      QRImgUrl: "",
      QRlink: "www.xxx.com",
      activeItem: false,
      imageUrl: "",
      dialogFormVisible: false,
      activeName: "first",
      uploadForm: {},
      formInline: {
        gameName: "",
        gameArea: null,
        status: 0,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      permissionForm: {},
      tableData: [],
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      PACKAGE_ING: 0,
      PACKAGED: 1,
      PACKAGE_FAIL: 2,
      timer: null,
      centerDialogVisible: false,
      currentGame: {},
    };
  },
  watch: {
    tableData: {
      handler(v) {
        v.forEach((ele) => {
          const now = this.$moment();
          const isFiveMin = now.diff(this.$moment(ele.timePoint), "minute") > 5;
          if (
            [this.PACKAGED, this.PACKAGE_FAIL].includes(ele.packStatus) ||
            isFiveMin
          ) {
            window.clearInterval(ele.timer);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();

    this.getAreaList();
  },
  methods: {
    getShareUrl(data) {
      return `${window.location.href.split("#")[0]}#/share/promotion?gameId=${
        data.id
      }&inviterId=${
        JSON.parse(localStorage.getItem("userInfo")).accountId
      }&inviteCode=${data.inviteCode}`;
    },
    getAreaList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryUnionAreaPlan({
          gameId: null,
          startTime: this.$moment().format("YYYY-MM-DD"),
          endTime: this.$moment().format("YYYY-MM-DD"),
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.areaList = result;
            let times = Math.ceil(this.areaList.length / 4);
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ani(data) {
      data -= 1;
      setTimeout(() => {
        const a =
          data < 0 ? 0 : (Math.ceil(this.areaList.length / 4) - data) * 42;
        this.$refs.bar.style = ` transition: 2s; transform:translateY(-${a}px);`;
      }, 2000);
      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style = `transtion:0;transform:translateY(0px);`;
          this.ani(Math.ceil(this.areaList.length / 4));
        } else {
          this.ani(data);
        }
      }, 6000);
    },
    changeList(e) {
      this.formInline.status = e;
      this.formInline.pageIndex = 1;
      this.getList();
    },
    downloadIamge(name) {
      var image = new Image();
      //跨域会导致a标签在部分浏览器中会直接打开新标签页 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png");

        // 生成一个a元素
        var a = document.createElement("a");
        // 创建一个单击事件
        var event = new MouseEvent("click");

        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
        a.download = name || "下载图片名称";
        // 将生成的URL设置为a.href属性
        a.href = url;

        // 触发a的单击事件
        a.dispatchEvent(event);
      };

      image.src = this.QRImgUrl;
    },
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    creatQrCode(data) {
      data = this.getShareUrl(data);
      const that = this;
      QRCode.toDataURL(data, function (err, url) {
        that.QRImgUrl = url;
      });
    },
    getList() {
      this.$api.gameApi
        .queryGameListMap({
          ...this.formInline,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result.map((item) => {
              return {
                ...item,
                loading: false,
                timer: null,
                timePoint: null,
                count: 0,
              };
            });
            this.formInline.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goDetail(id) {
      this.$router.push({
        path: "/game/detail",
        query: {
          id: id,
        },
      });
    },
    handleCurrentChange(page) {
      this.formInline.pageIndex = page;
      this.getList();
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    subContract(gameId, i) {
      const currentGame = this.tableData.find((ele) => ele.id === gameId);

      currentGame.timePoint = this.$moment();
      currentGame.loading = true; // 开启loading，展示分包中状态
      currentGame.count = 0;
      currentGame.packStatus = this.PACKAGE_ING; // 点击分包后返回成功，状态没变之前初始化为分包中

      // 清除当前game的定时器
      if (currentGame.timer) {
        window.clearInterval(currentGame.timer);
        currentGame.timer = null;
      }

      this.$api.gameApi.unionGamePack({ gameId }).then((res) => {
        if (res.code === 0) {
          this.checkPackStatus(gameId, i); // 第一次获取分包状态
          // 启动该分包活动的定时器
          currentGame.timer = window.setInterval(() => {
            this.checkPackStatus(gameId, i);
          }, 5000);
        } else {
          this.$message.error(res.msg);
          currentGame.loading = false;
        }
      });
    },
    checkPackStatus(gameId) {
      const currentGame = this.tableData.find((ele) => ele.id === gameId);

      this.$api.gameApi.queryPackStatus({ gameId }).then((res) => {
        if (res.code === 0) {
          const result = res.data || {};
          currentGame.count += 1;
          if ([this.PACKAGED, this.PACKAGE_FAIL].includes(result.packStatus)) {
            // 返回的分包状态成功或者失败，变成分包按钮
            currentGame.loading = false;
            window.clearInterval(currentGame.timer);
            this.getList();
          } else if (result.packStatus === this.PACKAGE_ING) {
            // 如果返回的分包状态是分包中，保持loading状态
            currentGame.loading = true;
          }
        } else {
          this.$message.error(res.msg);
          currentGame.loading = false; // 失败，显示分包按钮
        }
      });
    },

    updateQrcode(gameId) {
      this.$api.gameApi.refreshPackInviteCode({ gameId }).then(() => {
        this.getList();
        this.$message.success("操作成功!");
      });
    },

    checkCode(row) {
      this.creatQrCode(row);
      this.currentGame = row;
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .elp {
    color: #666;
    width: 100%;
    color: rgb(102, 102, 102);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .top-container {
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #255ec0;
      margin-left: 24px;
      margin-right: 40px;
    }
    .box {
      flex: 1;
      overflow: hidden;
      height: 42px;
      line-height: 42px;
      .bar {
        white-space: nowrap;
        .item {
          width: 25%;
          height: 100%;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
}
.game-icon {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 8px 0 12px;
  border-radius: 8px;
}
.qrcode {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.btn-container {
  display: flex;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  box-sizing: border-box;
  overflow: auto;
}

@media (max-width: 440px) {
  ::v-deep .el-dialog {
    width: 350px !important;
  }
}
</style>
