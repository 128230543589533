<template>
  <div class="login">
    <div class="top flex">
      <img class="logo" src="@/assets/img/avator.png" />
      <div class="title">Game最强王者联盟</div>
    </div>
    <div class="protrol">
      <el-dialog title="平台协议" :visible.sync="dialogVisible" width="70%">
        <div class="protocol">
          <Protocol></Protocol>
        </div>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </div>
    <div class="tip">
      <el-dialog width="300px" :visible.sync="centerDialogVisible">
        <span
          style="
            font-size: 16px;
            color: #666;
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: -20px;
          "
          >{{ contact[platformType] }}</span
        >
        <span slot="footer" class="dialog-footer">
          <div
            style="
              background: #27282c;
              cursor: pointer;
              width: 90%;
              margin: 10px auto;
              height: 42px;
              text-align: center;
              color: white;
              line-height: 42px;
              border-radius: 5px;
            "
            @click="centerDialogVisible = false"
          >
            确认
          </div>
        </span>
      </el-dialog>
    </div>

    <div class="container open">
      <img class="logo" src="@/assets/img/avator.png" />
      <div class="form-set">
        <div class="form-group">
          <div
            :class="{
              'form-label': true,
              'on-focus': accountNameFocus || ruleForm.accountName,
            }"
          >
            用户名
          </div>
          <input
            @focus="() => (accountNameFocus = true)"
            @blur="() => (accountNameFocus = false)"
            type="text"
            class="form-control"
            v-model="ruleForm.accountName"
            @keydown.enter.prevent="submitForm"
          />
        </div>
        <div class="form-group">
          <div
            :class="{
              'form-label': true,
              'on-focus': accountPasswordFocus || ruleForm.accountPassword,
            }"
          >
            密码
          </div>
          <input
            @focus="() => (accountPasswordFocus = true)"
            @blur="() => (accountPasswordFocus = false)"
            type="password"
            class="form-control"
            v-model="ruleForm.accountPassword"
            @keydown.enter.prevent="submitForm"
          />
        </div>
        <button @click="submitForm" class="btn" :disabled="loading">
          {{ loading ? "登录中..." : "登入" }}
        </button>
      </div>
      <div class="loginProtrol flex">
        <div class="loginProtrolLeft">
          <el-checkbox
            v-model="checked"
            style="margin-bottom: 2.5px; margin-right: 5px"
          >
          </el-checkbox>
          <span>请先同意</span>
          <el-button
            style="font-weight: normal; color: #ba4d4f"
            type="text"
            @click="dialogVisible = true"
            >《平台协议》</el-button
          >
        </div>
        <div @click="open" class="forgotlink">忘记密码?</div>
      </div>
    </div>
  </div>
</template>
<script>
import choosePlantorm from "@/utils/choosePlantorm";
import Protocol from "./Protocol.vue";

export default {
  name: "loginPage",

  components: {
    Protocol,
  },

  data() {
    return {
      loading: false, // 添加loading状态
      checked: true,
      centerDialogVisible: false,
      dialogVisible: false,
      ruleForm: {
        accountName: "",
        accountPassword: "",
      },
      accountNameFocus: false,
      accountPasswordFocus: false,
      platformType: choosePlantorm().platformType,
      contact: ["请联系管理员", "请联系客服", "请联系管理员"],
    };
  },

  mounted() {
    // 清除旧的登录信息
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");

    if (this.$route.query.timeout === "403") {
      this.$message.error("登录过期，请重新登录");
    }
  },

  methods: {
    // 打开忘记密码弹窗
    open() {
      this.centerDialogVisible = true;
    },

    // 表单验证
    validateForm() {
      if (!this.checked) {
        this.$message.error("请先同意平台协议");
        return false;
      }

      if (!this.ruleForm.accountName.trim()) {
        this.$message.error("请输入账号");
        return false;
      }

      if (!this.ruleForm.accountPassword.trim()) {
        this.$message.error("请输入密码");
        return false;
      }

      return true;
    },

    // 提交表单
    submitForm() {
      if (this.validateForm()) {
        this.login();
      }
    },

    // 处理登录后的路由跳转
    handleLoginSuccess(menuData) {
      if (!menuData || menuData.length === 0) {
        // 没有权限的账号跳转到无权限页面
        return this.$router.push("/noPermission");
      }

      // 进入第一个有权限的页面
      const firstMenu = menuData[0];
      if (firstMenu.children?.length) {
        const { path, id } = firstMenu.children[0];
        this.$router.push({ path, query: { pageId: id } });
      } else {
        this.$router.push(firstMenu.path);
      }
    },

    // 登录请求
    async login() {
      try {
        this.loading = true;

        // 登录请求
        const loginRes = await this.$api.loginApi.login({
          ...this.ruleForm,
          platformType: this.platformType,
        });

        if (loginRes.code !== 0) {
          throw new Error(loginRes.msg);
        }

        // 保存登录信息
        localStorage.setItem("token", loginRes.data.token);
        localStorage.setItem("userInfo", JSON.stringify(loginRes.data));
        this.$message.success("登录成功!");

        // 获取菜单树
        const treeRes = await this.$api.loginApi.tree({});
        if (treeRes.code !== 0) {
          throw new Error(treeRes.msg);
        }

        // 处理路由跳转
        this.handleLoginSuccess(treeRes.data);
        this.slideData = treeRes.data;
      } catch (error) {
        this.$message.error(error.message || "登录失败");
      } finally {
        this.loading = false;
      }
    },
  },

  // 组件销毁前清理
  beforeDestroy() {
    this.ruleForm.accountName = "";
    this.ruleForm.accountPassword = "";
  },
};
</script>
<style lang="less" scoped>
// 变量定义
@gray-colors: {
  70: darken(#ffffff, 70%);
  60: darken(#ffffff, 50%);
  50: darken(#ffffff, 50%);
  30: darken(#ffffff, 30%);
  0: darken(#ffffff, 5%);
};

// 动画变量
@animation: {
  from: -200px;
  to: 0px;
};

// 基础样式重置
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: @gray-colors[60];
}

// 登录页面主容器
.login {
  width: 100%;
  height: 100%;
  background: url(../../assets/img/login-bcg.jpg) no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;

  // 顶部区域
  .top {
    position: relative;
    top: 5%;
    height: 90px;
    display: flex;
    align-items: center;

    .logo {
      height: 80px;
      border-radius: 20px;
      padding: 5px;
      margin-left: 5%;
      margin-right: 15px;
    }

    .title {
      color: #666;
      font-size: 16px;
    }
  }

  // 登录容器
  .container {
    width: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
    margin: 13% auto;
    padding: 10px 0 30px;
    position: relative;
    transition: transform 0.5s ease;
    animation: page-down 1s;

    // Logo
    .logo {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 72px;
      margin-top: 20px;
    }

    // 表单区域
    .form-set {
      padding: 25px;
      max-width: 320px;
      margin: auto;
      border-bottom: 1px solid @gray-colors[30];

      .form-group {
        position: relative;
        margin-bottom: 15px;
        border-bottom: 1px solid @gray-colors[30];

        .form-label {
          position: absolute;
          transition: 0.15s all ease;

          &.on-focus,
          &.filled {
            font-size: 10px;
            top: -10px;
          }
        }

        .form-control {
          height: 25px;
          padding: 5px;
          width: 100%;
          position: relative;
          z-index: 2;
          background-color: transparent;
          border: none;
          outline: none;

          &:-internal-autofill-previewed,
          &:-internal-autofill-selected {
            -webkit-text-fill-color: #000;
            transition: background-color 5000s ease-out 0.5s;
          }
        }
      }

      // 登录按钮
      .btn {
        width: 100%;
        height: 40px;
        border: 1px solid @gray-colors[50];
        border-radius: 30px;
        background-color: transparent;
        font-size: 16px;
        margin: 15px 0;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    // 协议区域
    .loginProtrol {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      display: flex;

      .loginProtrolLeft {
        flex: 1;

        /deep/ .el-checkbox__input {
          &.is-checked .el-checkbox__inner,
          &.is-indeterminate .el-checkbox__inner {
            background-color: @gray-colors[70];
            border-color: @gray-colors[70];
          }
        }
      }

      .forgotlink {
        font-size: 12px;
        text-decoration: none;
        margin-top: -5px;
        text-align: right;
        line-height: 45px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// 协议弹窗样式
.protocol {
  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .protocol_bd {
    padding-top: 20px;
    max-height: calc(100vh - 420px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  h4 {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 400;
    text-indent: 30px;
  }

  p {
    font-size: 14px;
    text-indent: 30px;
    line-height: 28px;
  }
}

// 动画定义
@keyframes page-down {
  0% {
    transform: translateY(@animation[from]);
    opacity: 0;
  }
  100% {
    transform: translateY(@animation[to]);
    opacity: 1;
  }
}

// 响应式布局
@media (max-width: 500px) {
  .protrol {
    ::v-deep .el-dialog__body {
      height: 400px;
      overflow-y: auto;
    }
  }

  .container {
    margin: 30% auto !important;
    width: 90% !important;
  }

  .tip {
    ::v-deep .el-dialog {
      margin-top: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (min-width: 500px) {
  .container {
    &:hover {
      transform: scale(1.1);
    }
  }

  .tip {
    ::v-deep .el-dialog {
      margin-top: 0 !important;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
