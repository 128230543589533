<template>
  <div class="multipleUpload">
    <div class="file-list">
      <draggable v-model="fileList" @change="handleList" class="dragArea">
        <div v-for="(item, index) in fileList" :key="index" class="file-item">
          <img class="file-img" :src="item.url" alt="" />
          <div class="file-operate">
            <i class="el-icon-delete" @click="handleRemove(item)"></i>
          </div>
        </div>
      </draggable>
    </div>
    <label :for="id" class="plus-upload">
      <i class="el-icon-plus avatar-upload-icon"></i>
    </label>
    <div>
      <input
        type="file"
        :id="id"
        style="display: none"
        multiple
        @change="handleUpload"
      />
    </div>
    <div class="tips">
      只能上传jpg/png文件,且不超过10MB,最多上传{{ limit }}个文件{{
        min > 1 ? ",最少上传" + min + "个文件" : ""
      }}
      <template v-if="rotate > 0">
        <br />
        图片最佳尺寸: {{ rotateData[rotate].name }}
      </template>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "loginPage",
  components: {
    draggable,
  },
  // 接收v-model 双向绑定
  props: {
    // 个数
    limit: {
      type: Number,
      default: 6,
    },
    // 最小上传个数
    min: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    // 比例 0: 不限制 1: 9:16
    rotate: {
      type: [Number, String],
      default: 0,
    },
  },

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  watch: {
    // 监听modelValue

    modelValue: {
      handler(val) {
        this.fileList = val;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      id: Math.floor(Math.random() * 10000),
      vLoading: true,
      fileList: [],
      rotateData: {
        1: {
          w: 9,
          h: 16,
          name: "375px * 667px (9:16)",
          min: 0.55,
          max: 0.6,
        },
        2: {
          w: 16,
          h: 9,
          name: "667px * 375px (16:9)",
          min: 0.55,
          max: 0.6,
        },
        3: {
          w: 4,
          h: 3,
          name: "375px * 333px (9:8)",
          min: 0.55,
          max: 0.6,
        },
      },
    };
  },
  methods: {
    async handleUpload(e) {
      const files = e.target.files;
      if (files) {
        const fileArr = [];
        for (let i = 0; i < files.length; i++) {
          if (this.beforeUpload(files[i])) {
            fileArr.push(files[i]);
          }
        }
        // 限制上传个数
        if (fileArr.length + this.fileList.length > this.limit) {
          this.$message.error(
            "最多上传" + this.limit + "个文件,请删除后再上传"
          );
          return;
        }
        try {
          this.vLoading = true;
          for (let i = 0; i < fileArr.length; i++) {
            await this.uploadFile(fileArr[i]);
          }
        } finally {
          this.vLoading = false;
        }
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";
      const isMp4 = file.type === "video/mp4";

      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;
      const is100M = file.size / 1024 / 1024 < 100;
      if (this.isVideo) {
        //限制文件上传类型
        if (!isMp4) {
          this.$message.error("文件只能是 mp4 格式!");
          return false;
        }

        //限制文件上传大小
        if (!is100M) {
          this.$message.error("文件大小不能超过 100MB!");
          return false;
        }
        return true;
      }

      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("文件只能是 png,jpg,jpeg 格式!");
        return false;
      }

      //限制文件上传大小
      if (!is10M) {
        this.$message.error("文件大小不能超过 10MB!");
        return false;
      }

      return true;
    },
    imgWh(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();

        function getImageDimensions(imageUrl) {
          const img = new Image(); // 创建一个 Image 对象

          img.onload = function () {
            const width = img.width; // 获取图片宽度
            const height = img.height; // 获取图片高度
            resolve({ w: width, h: height });
          };

          img.src = imageUrl; // 设置图片的 src
        }

        reader.onload = function (e) {
          const imageUrl = e.target.result; // 获取图片的 data URL
          getImageDimensions(imageUrl); // 调用函数获取图片宽高
        };

        reader.readAsDataURL(file); // 读取文件内容
      });
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file);
      await this.$api.gameApi.uploadFile(formData).then((res) => {
        if (res.code === 0) {
          this.handleAvatarSuccess(res, file);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      const picUrl = res.data.uploadUrl || URL.createObjectURL(file.raw);
      this.fileList.push({
        name: Math.floor(Math.random() * 10000) + "-" + file.name,
        url: picUrl,
      });
      if (res.code === 0) {
        this.handleList();
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove(file) {
      this.fileList.splice(this.fileList.indexOf(file), 1);
      this.handleList();
    },
    log() {
      console.log(this.fileList);
    },
    handleList() {
      this.$emit("update:modelValue", this.fileList);
    },
  },
};
</script>
<style lang="less" scoped>
@avatar-upload-width: 100px;
.multipleUpload {
  .plus-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: @avatar-upload-width;
    height: @avatar-upload-width;
    line-height: @avatar-upload-width;
    display: inline-block;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    &:hover {
      border-color: #409eff;
    }
  }
  .file-list {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    cursor: move;
    .dragArea {
      display: flex;
      flex-wrap: wrap;
    }
    .file-item {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
      width: @avatar-upload-width;
      height: @avatar-upload-width;
      position: relative;
      overflow: hidden;
      &:hover {
        .file-operate {
          display: block;
        }
      }
    }
    .file-img {
      width: @avatar-upload-width;
      height: @avatar-upload-width;
      display: block;
      object-fit: contain;
    }
    .file-operate {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      display: none;
      border-radius: 4px;
      cursor: pointer;
      i {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .tips {
    font-size: 12px;
    color: #a33;
    margin-top: -10px;
    line-height: 18px;
  }
}
</style>
