<template>
  <div>
    <div class="title">
      <span class="tab-title" v-if="!isDone">充值记录</span>
      <div class="list_bar" v-else>
        <p>
          帐号ID：<span>{{ totalData.accountId }}</span>
        </p>
        <p>
          角色：<span>{{ totalData.gameRoleName }}</span>
        </p>
        <p>
          游戏：<span>{{ totalData.gameName }}</span>
        </p>
        <p>
          区服：<span>{{ totalData.gameServiceName }}</span>
        </p>
        <p>
          等级：<span>{{ totalData.roleLevel }}</span>
        </p>
        <p>
          充值总额：<span>{{ totalData.chargeTotalAmount }}</span>
        </p>
      </div>
      <el-button type="primary" size="mini" @click="exportExcel"
        >导出</el-button
      >
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :height="height"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="orderNo"
        label="订单号"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色名">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column align="center" prop="payType" label="支付方式">
        <template slot-scope="scope">
          {{
            typeof scope.row.payType === "number"
              ? +scope.row.payType === 0
                ? "微信"
                : "支付宝"
              : "无"
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ip" label="充值IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.ip }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="rechargeAmount" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="rechargeTime" label="充值时间">
      </el-table-column>
      <el-table-column align="center" prop="rechargeStatus" label="充值状态">
        <template slot-scope="scope">
          {{ statusList[scope.row.rechargeStatus] }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next"
      :page-size="20"
      :current-page.sync="midForm.pageIndex"
      @current-change="handleCurrentChange"
      :total="midForm.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
export default {
  props: {
    isDone: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 300,
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalData: {},
      statusList: {
        100: "创建中",
        200: "支付中",
        300: "发货中",
        400: "已完成",
        500: "已取消",
      },
      midForm: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      propParams: {},
      tableData: [],
    };
  },

  mounted() {},
  methods: {
    refresh(params = {}) {
      this.propParams = params;
      this.getMid();
      if (this.isInfo) {
        this.getRoleDetail();
      }
    },
    getRoleDetail() {
      const params = {
        ...this.propParams,
      };
      this.$api.dataApi
        .gameRoleDetail(params)

        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : 0;
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMid() {
      // 获取公会列表
      this.$api.dataApi
        .queryRechargeListV2({
          ...this.midForm,
          ...this.propParams,
          orderStatus: this.isDone ? 400 : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            // this.midForm.pageIndex = res.data.pageIndex;
            this.midForm.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.midForm.pageIndex = val;
      this.getMid();
    },
    exportExcel() {
      // 导出Excel
      this.$api.dataApi
        .exportRechargeListV2({
          ...this.midForm,
          ...this.propParams,
        })
        .then((res) => {
          // 导出Excel
          this.$exportExcel(
            res,
            `充值记录数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  .tab-title {
    font-size: 20px;
    font-weight: bold;
  }
}
.list_bar {
  padding: 0 16px;
  line-height: 40px;
  margin-left: -16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 24px;
  span {
    color: #255ec0;
  }
}
</style>
