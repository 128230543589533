<template>
  <div class="nav">
    <div class="title flex">
      <!-- <img src="@/assets/img/logo.svg" />
      <h1>OMINI.BASS</h1> -->
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical"
      @open="handleOpen"
      @close="handleClose"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#ffd04b"
      :unique-opened="true"
      :router="true"
      :collapse="isFold"
    >
      <div v-for="item in slideData" :key="item.id">
        <el-menu-item
          :index="item.path"
          :route="{
            path: item.path,
            query: {
              pageId: item.id,
            },
          }"
          v-if="!item.children || item.children.length === 0"
        >
          <i class="el-icon-setting"></i>
          <!-- <i style="width: 20px"></i> -->
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
        <el-submenu :index="`${item.id}`" v-else>
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="i in item.children"
              :key="i.id"
              :index="i.path"
              :route="{
                path: i.path,
                query: {
                  pageId: i.id,
                },
              }"
              >{{ i.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  data() {
    return {
      defaultPath: "",
      slideData: [],
    };
  },
  props: {
    isFold: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
    this.$api.loginApi.tree({}).then((res) => {
      if (res.code === 0) {
        this.slideData = res.data;
        this.$store.commit("setMenuTree", res.data);
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  methods: {
    handleOpen() {
      console.log();
    },
    handleClose() {
      console.log();
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  overflow: hidden;
  .el-menu-vertical {
    border: none;
  }
  .title {
    width: 100%;
    padding-left: 24px;
    height: 64px;
    color: #fff;
    font-size: 20px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 600;
    vertical-align: middle;
  }
  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
</style>
<style scoped>
/* 文字 */
::v-deep.el-submenu.is-active > .el-submenu__title {
  color: rgb(255, 208, 75) !important;
}
/* icon图标也跟着变 */
::v-deep .el-submenu.is-active > .el-submenu__title i {
  color: rgb(255, 208, 75) !important;
}

.el-menu--collapse .el-submenu__title span {
  display: none;
}
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>
