<template>
  <div class="game-apply ground-white">
    <div class="top">
      <div class="left">
        <h1>账号转移</h1>
        <el-form ref="accountForm" :model="accountForm" label-width="120px">
          <el-form-item required prop="gameAccountId" label="游戏账号ID">
            <el-input
              type="number"
              v-model.number="accountForm.gameAccountId"
              style="width: 210px"
            ></el-input>
          </el-form-item>
          <InviterSelect
            propId="afterInviterAdminAccountId"
            v-model="accountForm.afterInviterAdminAccountId"
            :multiple="false"
            sty="width: 212px"
            :options="inviterOptions"
            :disable-branch-nodes="true"
          />
          <el-form-item prop="opDesc" label="转移原因">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              maxlength="100"
              rows="5"
              show-word-limit
              v-model="accountForm.opDesc"
            ></el-input>
          </el-form-item>
          <el-form-item prop="opDesc" label="">
            <el-button type="primary" size="middle" @click="addUnion(1)"
              >提交</el-button
            >
          </el-form-item>
          <el-row>
            <p style="padding-left: 120px; color: red">
              本次转移将包含相同游戏账号ID下的所有角色
            </p>
          </el-row>
        </el-form>
      </div>
      <div class="right">
        <h1>角色转移</h1>
        <el-form ref="roleForm" :model="roleForm" label-width="220px" inline>
          <el-form-item prop="gameId" label="游戏">
            <el-select
              v-model="roleForm.gameId"
              clearable
              placeholder="请选择游戏名称"
              @change="changeGame"
            >
              <el-option
                v-for="item in gameListData"
                :key="item.id"
                :label="item.gameName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="gameArea" required label="区服">
            <!-- <el-input v-model="roleForm.gameArea"></el-input> -->
            <el-select
              v-model="roleForm.gameArea"
              clearable
              placeholder="请选择区服"
              @change="changeArea"
            >
              <el-option
                v-for="item in areaListData"
                :key="item.id"
                :label="item.serviceName"
                :value="item.id"
              >
              </el-option>
            </el-select> </el-form-item
          ><br />
          <el-form-item prop="gameRoleId" required label="角色">
            <el-select
              v-model="roleForm.gameRoleId"
              clearable
              placeholder="请输入角色"
              filterable
              remote
              :remote-method="remoteMethodNew"
              :loading="loadingNew"
              @change="changeRole"
            >
              <el-option
                v-for="item in gameRoleListData"
                :key="item.id"
                :label="item.roleName"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <InviterSelect
            propId="afterInviterAdminAccountId"
            v-model="roleForm.afterInviterAdminAccountId"
            :multiple="false"
            sty="width: 200px"
            :options="inviterOptions"
            :disable-branch-nodes="true"
          />
          <br />
          <p
            v-if="roleForm.gameRoleId"
            style="margin-left: 145px; padding-bottom: 20px"
          >
            游戏账号ID：{{
              roleForm.gameAccountId
            }}
            &nbsp;&nbsp;&nbsp;&nbsp;当前推广员：{{
              roleForm.inviterAdminAccountName
            }}
          </p>
          <el-form-item prop="opDesc" label="转移原因">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              maxlength="100"
              show-word-limit
              rows="5"
              v-model="roleForm.opDesc"
              style="width: 360px"
            ></el-input> </el-form-item
          ><br />
          <el-form-item style="margin-left: 250px">
            <el-button type="primary" size="middle" @click="addUnion(2)">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <!--  -->
    <el-form :inline="true" :model="formInline">
      <el-form-item prop="gameAccountId" label="游戏账号ID">
        <el-input
          v-model.number="formInline.gameAccountId"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="opAccountId" label="操作人">
        <el-select
          v-model="formInline.opAccountId"
          clearable
          placeholder="请输入操作人"
          filterable
          remote
          :remote-method="remoteAccount"
          :loading="loadingNew"
        >
          <el-option
            v-for="item in gameAccountListData"
            :key="item.id"
            :label="item.opAccountName"
            :value="item.opAccountId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作日期">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="timestamp"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="id" label="记录ID" width="100">
      </el-table-column>
      <el-table-column align="center" prop="type" label="转移方式" width="80">
        <template slot-scope="scope">
          {{ scope.row.type === 1 ? "账户" : "角色" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="玩家信息">
        <template slot-scope="scope">
          {{
            scope.row.type === 1
              ? "-"
              : `${scope.row.gameName}/${scope.row.gameArea}/${scope.row.gameRoleName}`
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="beforeInviterAdminName"
        label="转移前"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="afterInviterAdminName"
        label="转移后"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="操作时间"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="opAccountName"
        width="150"
        label="操作人"
      >
      </el-table-column>
      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="opDesc"
        label="转移原因"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      loading: false,
      loadingNew: false,
      accountForm: {
        gameAccountId: null,
        afterInviterAdminAccountId: null,
        opDesc: null,
      },
      roleForm: {
        gameId: null,
        gameArea: null,
        gameRoleId: null,
        afterInviterAdminAccountId: null,
        beforeInviterAdminAccountId: null,
        opDesc: null,
        inviterAdminAccountName: "",
        gameAccountId: null,
      },
      pickerOptions: {
        shortcuts,
      },
      permissionCode: [],
      gameListData: [],
      areaListData: [],
      staffListData: [],
      gameRoleListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: [],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      inviterOptions: [],
      gameAccountListData: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getAllInviter();
  },
  methods: {
    // 查询操作人
    remoteAccount(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryOperator({
            operatorName: query,
          })
          .then((res) => {
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameAccountListData = result;
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.gameAccountListData = [];
      }
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            // accountIdList: [0],
            firstChargeInfo: true,
            roleName: query,
            // gameAccountIdList: [0],
            gameIdList: this.roleForm.gameId ? [this.roleForm.gameId] : null,
            gameServerIdList: this.roleForm.gameArea
              ? [parseInt(this.roleForm.gameArea)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    changeArea(e) {
      console.log(e);
    },
    changeRole(e) {
      console.log(e);
      if (!e) {
        return;
      }
      this.$api.organizationApi
        .queryGameRole({
          // accountIdList: [0],
          firstChargeInfo: true,
          // gameAccountIdList: [0],
          gameIdList: this.roleForm.gameId ? [this.roleForm.gameId] : null,
          gameServerIdList: this.roleForm.gameArea
            ? [parseInt(this.roleForm.gameArea)]
            : null,
          needAccountInfo: true,
          roleIdList: [`${e}`],
          // roleName: "string",
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            this.roleForm.beforeInviterAdminAccountId =
              res.data[0].inviterAdminAccount;
            this.roleForm.gameAccountId = res.data[0].gameAccountId;
            this.roleForm.inviterAdminAccountName =
              res.data[0].inviterAdminAccountName;
            // const result = res.data ? res.data : [];
            // this.gameRoleListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(e) {
      console.log(e);
      this.roleForm.gameArea = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryChangeLogV2({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time
            ? this.formInline.time[1] + 86400000
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion(pType) {
      let requestForm = pType === 1 ? this.accountForm : this.roleForm;
      let refName = pType === 1 ? "accountForm" : "roleForm";
      this.$api.organizationApi
        .AddChangeLog({
          type: pType,
          ...requestForm,
        })
        .then((res) => {
          console.log("createUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.$refs[refName].resetFields();
            // if (pType === 1) {
            //   this.resetForm("accountForm");
            // } else {
            //   this.resetForm("roleForm");
            // }
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .top {
    width: 100%;
    display: flex;
    h1 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    // box-sizing: border-box;
    justify-content: space-between;
    .left,
    .right {
      // box-sizing: border-box;
      // height: 340px;
      // overflow: hidden;
    }
    .right {
      flex: 1.5;
      margin-right: 60px;
      h1 {
        padding-left: 100px;
      }
    }
    .left {
      flex: 1;
      // padding: 10px 20%;
      padding-right: 20%;
      margin-left: 60px;
      border-right: 8px solid #efefef;
    }
  }
}
</style>
