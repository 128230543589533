<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="游戏">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏"
          @change="changeGame"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.serverId"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        label=""
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        sty="width: 220px"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.gameRoleId"
          clearable
          placeholder="请输入角色"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="登录开始日期"
          end-placeholder="登录结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time1"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="充值开始日期"
          end-placeholder="充值结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        游戏数：<span>{{ totalData.gameNum }}</span>
      </p>
      <p>
        推广员数：<span>{{ totalData.inviterAccountNum }}</span>
      </p>
      <p>
        角色创建数：<span>{{ totalData.roleCreateNum }}</span>
      </p>
      <p>
        角色活跃数：<span>{{ totalData.roleActiveNum }}</span>
      </p>
      <p>
        充值角色数：<span>{{ totalData.chargeRoleNum }}</span>
      </p>
      <p>
        充值总额：<span>{{ totalData.chargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="unionName" label="工会归属">
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.inviterAccountName"
            :inviterAccountId="scope.row.accountLabel?.accountId"
            :departmentName="scope.row.accountLabel?.departmentName"
            :roleName="scope.row.accountLabel?.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏帐号">
        <template slot-scope="scope">
          <RoleMark
            type="account"
            :rowData="scope.row"
            :colorMark="scope.row.gameAccountColorMark"
            :showData="scope.row.gameAccountId"
            :getList="getList"
            :hasPermission="true"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameRoleId" label="角色id">
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色名">
        <template slot-scope="scope">
          <RoleMark
            type="role"
            :rowData="scope.row"
            :colorMark="scope.row.gameRoleColorMark"
            :showData="scope.row.gameRoleName"
            :getList="getList"
            :hasPermission="true"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="supportGameRole" label="扶持角色">
        <template slot-scope="scope">
          {{ scope.row.supportGameRole === 0 ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="roleLevel" label="等级">
      </el-table-column>
      <el-table-column align="center" prop="gameServiceName" label="区服名">
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏">
      </el-table-column>
      <el-table-column
        align="center"
        prop="registerIp"
        label="创建ip"
        width="130"
      >
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.registerIp }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="firstLoginTime" label="首次登录">
      </el-table-column>
      <el-table-column align="center" prop="lastLoginTime" label="最近登录">
      </el-table-column>
      <el-table-column align="center" prop="lastOfflineTime" label="下线时间">
      </el-table-column>
      <el-table-column align="center" prop="lastChargeTime" label="充值时间">
      </el-table-column>
      <el-table-column align="center" prop="chargeTotalAmount" label="充值总额">
      </el-table-column>
      <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >查看明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("unionGameRole");
export default {
  data() {
    return {
      loadingNew: false,
      pickerOptions: {
        shortcuts,
      },
      getGameListData: [],
      areaListData: [],
      gameRoleListData: [],
      loading: false,
      formInline: {
        gameAccountId: "",
        inviterAccountIdList: [],
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
        time1: ["", ""],
        gameId: "",
        gameRoleId: "",
        serverId: "",
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    if (curdParams.getShow) {
      curdParams.setShow(false);
      let params = curdParams.getParams;
      if (params) {
        this.formInline = { ...this.formInline, ...params };
      }
    }
    this.getGameList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            // accountIdList: [0],
            firstChargeInfo: true,
            roleName: query,
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.serverId
              ? [parseInt(this.formInline.serverId)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    changeGame(e) {
      this.formInline.serverId = null;
      this.getArea(e);
    },
    exportList() {
      this.formInline.pageSize = 50000;
      this.$api.dataApi
        .gameRoleExportNew({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          this.formInline.pageSize = 20;
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      this.$api.dataApi
        .gameRoleTotal({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let params = { ...this.formInline };
      curdParams.setParams(params);
      this.$api.dataApi
        .gameRoleQueryNew({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .querySubPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      this.$router.push({
        path: "/data/union/game/role/detail",
        query: {
          gameRoleId: e.gameRoleId,
          gameAccountId: e.gameAccountId,
          gameId: e.gameId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
