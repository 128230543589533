<template>
  <div class="show_account_icon">
    <span v-if="text"
      >{{ text }}
      <el-tooltip
        class="item"
        content="Top Center 提示文字"
        placement="top"
        v-if="list && list.length"
      >
        <div slot="content" class="tip_wrap">
          <div style="margin-bottom: 6px">曾用手机号信息</div>
          <div
            style="margin-bottom: 6px"
            v-for="(item, i) in list"
            :key="i + item.mobile"
          >
            手机号: {{ item.mobile }}
            <br />
            换绑时间: {{ item.changeTime }}
          </div>
        </div>
        <i class="el-icon-tickets" style="color: #255ec0" />
      </el-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  props: ["text", "list"],
};
</script>
<style lang="less" scoped>
.show_account_icon {
  .tip_wrap {
    display: flex;
    flex-direction: column;
    line-height: 32px;

    div {
      marign-bottom: 10px;
    }
  }
}
</style>
