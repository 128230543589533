<template>
  <div>
    <div class="loading-page" v-if="!isInit">
      <LoadingIcon />
    </div>
    <div class="fade-in" v-show="isInit">
      <div class="unionList">
        <div class="type_sslc">
          <div v-show="isShow">
            <div
              class="top"
              :style="{
                'background-image': `url(${gameConfig.topBg || topBg})`,
              }"
            >
              <div
                style="width: 60vw; display: flex; justify-content: flex-start"
              >
                <img :src="gameConfig.logo" class="icon" />
                <div style="padding-left: 10px">
                  <p style="color: #ffd56a; font-size: 0.2rem">
                    {{ gameConfig.title }}
                  </p>
                  <p style="color: #ffffff; font-size: 0.12rem">
                    {{ gameConfig.content }}
                  </p>
                </div>
              </div>
              <span
                :data-clipboard-text="copyInfo"
                class="btn"
                @click="download"
              >
                下载游戏
              </span>
            </div>
            <!-- first part -->
            <div class="big_video" v-if="gameConfig.publicizeVideo">
              <video
                :src="gameConfig.publicizeVideo"
                class="video"
                :class="{ load: isLoadVideo }"
                autoplay
                loop
                muted
                playsinline
                controls
                @canplay="isLoadVideo = true"
                poster="gameConfig.publicizeImg"
              ></video>
            </div>
            <div class="big_video" v-else-if="gameConfig.publicizeImg">
              <img class="img" :src="gameConfig.publicizeImg" alt="" />
              <span class="new-service" v-if="showServerInfo.serviceName">
                <span
                  style="
                    background: rgba(20, 25, 54, 0.8);
                    color: #fff4da;
                    padding: 2px 4px;
                    border-radius: 3px 0px 0px 3px;
                  "
                  >新服推荐</span
                >
                <span
                  style="
                    background: rgba(255, 255, 255, 0.9);
                    color: #141936;
                    padding: 2px 7px 2px 0;
                    border-radius: 0px 3px 3px 0px;
                  "
                  >【{{ showServerInfo.serviceName }}】{{ openDay }}({{
                    openTime
                  }})开启</span
                >
              </span>
            </div>

            <!-- second part -->
            <div
              class="banner1"
              :class="{ horizontal: gameConfig.secondType == '2' }"
              :style="{
                'background-image': `url(${gameConfig.secondBg || secondBg})`,
              }"
              v-if="gameConfig.isSecond && gameConfig.secondType == '2'"
            >
              <div class="title">{{ gameConfig.secondTitle }}</div>
              <p class="desc">{{ gameConfig.secondContent }}</p>
              <div class="swiper mySwiper" v-if="gameConfig">
                <div
                  class="swiper-wrapper"
                  v-if="gameConfig.secondList && gameConfig.secondList.length"
                >
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in gameConfig.secondList"
                    :key="index"
                  >
                    <img :src="item.url" />
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
            <div
              class="banner1"
              v-if="gameConfig.isSecond && gameConfig.secondType === '1'"
              :style="{
                'background-image': `url(${gameConfig.secondBg || secondBg})`,
              }"
            >
              <div class="title">{{ gameConfig.secondTitle }}</div>
              <p class="desc">{{ gameConfig.secondContent }}</p>
              <div class="swiper mySwiper" v-if="gameConfig">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in gameConfig.secondList"
                    :key="index"
                  >
                    <img :src="item.url" />
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>

            <!-- third part -->
            <div
              class="banner2"
              :style="{
                'background-image': `url(${gameConfig.threeFourBg || thirdBg})`,
              }"
            >
              <template v-if="gameConfig.isThree">
                <div class="title" style="margin-bottom: 0">
                  {{ gameConfig.threeTitle }}
                </div>
                <div class="swiper mySwiper2" v-if="gameConfig">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="(item, index) in gameConfig.threeList"
                      :key="index"
                    >
                      <img :src="item.url" />
                    </div>
                  </div>
                  <div class="swiper-pagination2"></div>
                </div>
              </template>
              <div class="title">游戏开服表</div>
              <div class="area">
                <ul class="a-top">
                  <li>游戏名称</li>
                  <li>开服时间</li>
                  <li>服务器</li>
                </ul>
                <div class="bar">
                  <div class="baritem" ref="bar">
                    <ul
                      class="a-bottom"
                      v-for="(item, index) in areaList"
                      :key="index"
                    >
                      <li class="tel">{{ gameConfig.title }}</li>
                      <li class="tel">
                        {{ $moment(item.serviceTime).format("MM-DD") }}({{
                          $moment(item.serviceTime).format("HH:mm")
                        }})
                      </li>
                      <li class="tel">{{ item.serviceName }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <img class="icon" :src="gameConfig.logo" />
              <span
                :data-clipboard-text="copyInfo"
                class="btn"
                @click="download"
              >
                下载游戏
              </span>
            </div>
            <div class="bottom">
              <span
                >抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。
                适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</span
              >
            </div>
          </div>
          <div v-show="!isShow">请到移动端访问该页面</div>
          <div class="modal" v-show="modalShow" ref="mask"></div>
          <div v-if="isShowSign" class="sign_wrap">
            <i class="sign_close" @click="closeSign"></i>
            <div v-if="!isSign" class="sign_hd">
              <img src="@/assets/img/sign_logo.png" alt="" />
            </div>
            <div class="sign_bd">
              <div v-if="!isSign">
                <div class="ipt_bg" v-if="!isTrueCode">
                  <input
                    v-model="mobileInfo.mobile"
                    placeholder="请输入手机号码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                    type="text"
                  />
                  <div class="verify_wrap">
                    <span v-if="accountSec" class="verify_txt"
                      >{{ accountSec }}秒后再次点击</span
                    >
                    <span v-else class="btn_txt" @click="sendSMSCode"
                      >获取验证码</span
                    >
                  </div>
                </div>
                <div class="ipt_bg" v-if="!isTrueCode">
                  <input
                    v-model="mobileInfo.code"
                    placeholder="请输入短信验证码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="6"
                    type="text"
                  />
                </div>
                <div class="ipt_bg" v-if="isTrueCode">
                  <input
                    v-model="mobileInfo.password"
                    placeholder="请设置6-12位密码"
                    type="password"
                  />
                </div>
                <div class="info_wrap">
                  <span>下载前，请先注册</span>
                </div>
                <button v-if="!isTrueCode" class="next_btn" @click="gotoVerify">
                  下一步
                </button>
                <button
                  v-if="isTrueCode"
                  class="next_btn pre_btn"
                  @click="goBackToGetVerify"
                >
                  上一步
                </button>
                <button
                  v-if="isTrueCode"
                  class="next_btn"
                  @click="createAccount"
                >
                  完成注册
                </button>
              </div>
              <div v-if="isSign" class="sign_text">已经注册，请下载游戏</div>
              <button v-if="isSign" class="next_btn" @click="gotoDownload">
                普通安装
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 非微信环境打开 -->
      <div
        v-if="isShowWxDownload"
        class="wx_download"
        @click="isShowWxDownload = false"
      ></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import LoadingIcon from "./loading.vue";
import Clipboard from "clipboard";
let u = navigator.userAgent;

export default {
  components: {
    LoadingIcon,
  },
  data() {
    const { gameId, inviteCode, inviterId } = this.$route.query;
    return {
      isInit: false,
      isShow: true,
      modalShow: false,
      areaList: [],
      copyInfo: null,
      appUrl: `/#${this.$route.fullPath}`,
      showServerInfo: {},
      openDay: "",
      openTime: "",
      isIOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      isWx: !!u.match(/MicroMessenger/i),
      accountSec: null,
      mobileInfo: {
        mobile: "",
        code: "",
      },
      isTrueCode: false,
      isShowSign: false,
      isSign: false,
      gameId,
      inviteCode,
      inviterId,
      gameConfig: {},
      // 去浏览器下载弹窗
      isShowWxDownload: false,
      // 视频加载完成
      isLoadVideo: false,
      topBg:
        "https://yunding-static.oss-cn-shenzhen.aliyuncs.com/17game/img/image_bg1.png",
      secondBg: "https://pic.zhongyiyf.com/img/image_bg2.f0faf215.png",
      thirdBg: "https://pic.zhongyiyf.com/img/image_bg3.30afa74c.png",
    };
  },
  watch: {
    isShowSign(v) {
      if (!v) {
        this.mobileInfo = {
          mobile: "",
          code: "",
          password: "",
        };
        this.accountSec = null;
      }
    },
  },
  created() {
    this.isShow = this.isMobile() ? true : false;
  },
  mounted() {
    this.getRem();
    this.initPage();
  },
  methods: {
    redirectToPage() {
      this.$router.push({
        path: "/share/gsws",
        query: {
          gameId: this.gameId,
          inviterAdminAccountId: this.inviterId,
        },
      });
    },
    getPageInfo() {
      const { gameId, inviteCode, inviterId } = this;
      this.$api.gameApi
        .getPageConfigInfo({
          gameId,
          inviteCode,
          inviterId,
        })
        .then((res) => {
          if (res.code === 0) {
            if (!res.data.pageInfo) {
              this.redirectToPage();
              return;
            }
            this.gameConfig = JSON.parse(res.data.pageInfo);
            document.title = this.gameConfig.title;

            this.appUrl = res.data.androidPacUrl;
            this.areaList = [...res.data.gameServiceList].reverse();
            const times = Math.ceil(this.areaList.length / 3);
            this.showServerInfo = [...res.data.gameServiceList].pop() || {};
            this.openDay = this.$moment(this.showServerInfo.serviceTime).format(
              "MM-DD"
            );
            this.openTime = this.$moment(
              this.showServerInfo.serviceTime
            ).format("HH:mm");
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }

          setTimeout(() => {
            this.isInit = true;
            this.$nextTick(() => {
              this.initSwiper();
            });
          }, 500);
        });
    },
    getRem() {
      const html = document.documentElement;
      const oWidth = window.innerWidth || html.clientWidth;

      html.style.fontSize = `${(oWidth / 375) * 100}px`;
    },
    async initPage() {
      const { inviterId, inviteCode } = this;
      this.copyInfo = JSON.stringify({ inviterId, inviteCode });
      this.getPageInfo();
    },
    ani(data) {
      data = Math.max(data - 1, 0);
      const translateY = (Math.ceil(this.areaList.length / 3) - data) * 32 * 3;
      this.$refs.bar.style.transition = "transform 2s";
      this.$refs.bar.style.transform = `translateY(-${0.01 * translateY}rem)`;

      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style.transition = "none";
          this.$refs.bar.style.transform = "translateY(0px)";
          this.ani(Math.ceil(this.areaList.length / 3));
        } else {
          this.ani(data);
        }
      }, 6000);
    },

    isMobile() {
      return true;
    },
    initSwiper() {
      const swiperConfig = {
        loop: true,
        loopAdditionalSlides: 1,
        initialSlide: 2,
        autoplay: 2200,
        autoplayDisableOnInteraction: false,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: true,
        },
      };
      if (this.gameConfig.secondType === "2") {
        swiperConfig.effect = "horizontal";
        swiperConfig.slidesPerView = 1;
      }
      new Swiper(".mySwiper", swiperConfig);
      new Swiper(".mySwiper2", {
        loop: true,
        autoplay: 2200,
        autoplayDisableOnInteraction: false,
        pagination: ".swiper-pagination2",
      });
    },
    countDown(counts, type) {
      this[type] = counts;
      this.timer = setTimeout(() => {
        counts--;
        this.countDown(counts, type);
      }, 1000);

      if (counts === 0) {
        clearTimeout(this.timer);
        this.counts = 0;
      }
    },
    sendSMSCode() {
      const { mobile } = this.mobileInfo;
      if (!mobile) {
        this.$message.warning("请输入手机号码！");
        return;
      }

      this.$api.billingApi
        .sendSmsCode({ mobile, smsCodeType: 0 })
        .then(({ code, msg }) => {
          if (code === 0) {
            this.countDown(60, "accountSec");
            this.$message.success("验证码发送成功!");
          } else {
            this.$message.error(msg);
          }
        });
    },
    closeSign() {
      this.isShowSign = false;
      this.modalShow = false;
      this.goBackToGetVerify();
      this.mobileInfo = { mobile: "", code: "" };
    },
    gotoVerify() {
      const { mobile, code } = this.mobileInfo;

      if (!mobile) {
        return this.$message.warning("请输入手机号码！");
      }
      if (!code) {
        return this.$message.warning("请输入验证码！");
      }

      this.$api.billingApi
        .verifySmsCode({
          mobile,
          smsCode: code,
          smsCodeType: 1,
        })
        .then(({ code: responseCode, msg }) => {
          if (responseCode === 0) {
            this.isTrueCode = true;
          } else {
            this.$message.error(msg);
          }
        });
    },
    goBackToGetVerify() {
      this.isTrueCode = false;
    },
    createAccount() {
      const { password, mobile } = this.mobileInfo;

      if (!password) {
        return this.$message.warning("请输入密码！");
      }

      this.$api.billingApi
        .createAccount({
          mobile,
          password,
          inviterAdminAccountId: this.inviterId,
          gameId: this.gameId,
        })
        .then(({ code, msg }) => {
          if (code === 0) {
            this.$message.success("注册成功！");
            this.resetMobileInfo();
            this.isSign = true;
          } else {
            this.handleErrorResponse(code, msg);
          }
        });
    },

    resetMobileInfo() {
      this.mobileInfo = { mobile: "", code: "", password: "" };
    },

    handleErrorResponse(code, msg) {
      if (+code === 100002001) {
        this.resetMobileInfo();
        this.isTrueCode = false;
        this.isSign = true;
      }
      this.$message.error(msg);
    },
    gotoDownload() {
      this.isShowSign = false;
      this.modalShow = false;
      this.isSign = false;

      const url = this.gameConfig.iosDownloadUrl;
      const a = document.createElement("a");
      a.href = url;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    download() {
      if (this.isWx) {
        this.isShowWxDownload = true;
        return;
      }

      if (this.isIOS) {
        this.isShowSign = true;
        this.modalShow = true;
        return;
      }

      const aLink = document.createElement("a");
      aLink.href = this.appUrl;
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);

      const clipboard = new Clipboard(".btn");
      clipboard.on("success", () => clipboard.destroy());
      clipboard.on("error", (e) => {
        console.error("该浏览器不支持自动复制", e);
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-page {
  width: 100vw;
  height: 100vh;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.tel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom {
  z-index: 9990;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  span {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(686px);
    animation: 14.0933s linear 1s infinite normal none running textRunning;
  }
}
@-webkit-keyframes textRunning {
  100% {
    transform: translateX(-686px);
  }
}
@keyframes textRunning {
  100% {
    transform: translateX(-682px);
  }
}
.bar {
  height: 1rem;
  overflow: hidden;
}
/deep/ .btn-close {
  width: 0.25rem;
  height: 0.25rem;
  background-color: #828282;
  color: whitesmoke;
  font-size: 0.24rem;
  position: absolute;
  right: 0.02rem;
  top: 1.65rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .video-view {
  width: 100%;
  height: 2.18rem;
  margin-top: 1.6rem;
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: auto;
  top: 0;
  z-index: 3;
}
@import url("../../../node_modules/swiper/dist/css/swiper.css");
/deep/ .swiper-pagination2 {
  text-align: center;
}
/deep/ .swiper-pagination-bullet {
  width: 0.18rem;
  height: 0.02rem;
  border-radius: 0;
  background: #eee;
}
.btn {
  width: 1.22rem;
  height: 0.44rem;
  color: #fff9b8;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.18rem;
  background: inherit;
  background-image: url("@/assets/img/share/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.title {
  width: 2rem;
  height: 0.4rem;
  line-height: 0.3rem;
  text-align: center;
  font-weight: normal;
  color: #fff4da;
  font-size: 0.2rem;
  background-image: url("@/assets/img/share/ztx-title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.2rem auto;
  margin-bottom: 0.1rem;
}
.desc {
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #fff4da 0%, #ffce75 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.3rem;
}
.swiper {
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 2rem;
  height: 3.56rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.horizontal {
  .swiper-slide {
    height: 2.1rem;
  }
  .swiper-slide img {
    height: 2.1rem;
  }
}
.unionList {
  box-sizing: border-box;
  overflow: auto;
  .footer {
    width: 100%;
    height: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1d2741;
    box-sizing: border-box;
    padding-left: 0.12rem;
    padding-right: 0.18rem;
    .icon {
      height: 0.6rem;
      width: 0.6rem;
    }
  }

  .top {
    box-sizing: border-box;
    padding: 0 0.16rem;
    padding-right: 0.1rem;
    height: 0.8rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .icon {
      width: 0.44rem;
      height: 0.44rem;
    }
  }
  .banner1 {
    width: 100%;
    height: 5.02rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    &.horizontal {
      height: 3.4rem;
    }
  }
  .banner2 {
    overflow: hidden;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .area {
      .a-top,
      .a-bottom {
        color: #141936;
        display: flex;
        align-items: center;
        width: 82%;
        height: 0.4rem;
        margin: 0 auto;

        li {
          &:nth-of-type(2) {
            width: 36%;
          }
          &:nth-of-type(3) {
            width: 28%;
          }
          width: 36%;
          text-align: center;
          font-size: 0.14rem;
        }
      }
      .a-bottom {
        height: 0.32rem;
      }
      box-sizing: border-box;
      padding-top: 0.186rem;
      width: 3.5rem;
      height: 1.9rem;
      margin: 0 auto;
      background-image: url("https://pic.zhongyiyf.com/img/area-bg.cfd4d76a.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0.3rem;
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
<style lang="less" scoped>
.sign_wrap {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%;);
  padding: 0 36px;
  width: 300px;
  min-height: 80px;
  border-radius: 4px;
  background: #0d1621;

  .sign_close {
    position: absolute;
    top: 4px;
    right: 4px;
    display: block;
    width: 32px;
    height: 32px;
    background: url("@/assets/img/close_sign.png") no-repeat;
    background-size: cover;
  }

  .sign_hd {
    position: relative;
    height: 76px;
    border-bottom: 1px solid #efefef;
    text-align: center;

    &::after {
      content: "";
    }

    img {
      margin-top: 14px;
      width: 48px;
      height: 48px;
    }
  }

  .sign_bd {
    padding-top: 16px;
    .info_wrap {
      padding-top: 9px;
      padding-left: 16px;
      span {
        font-size: 14px;
        color: #969696;
      }
    }
    .ipt_bg {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);

      input {
        padding-left: 12px;
        flex: 1;
        background: rgba(255, 255, 255, 0);
        outline: none;
        border: none;
        color: #fff;

        &::placeholder {
          color: #888;
        }
      }

      .verify_wrap {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #449fc6;

        span {
          display: inline-block;
        }
        .btn_txt {
          width: 100px;
        }
        .verify_txt {
          width: 120px;
          color: #5c5c5c;
        }
      }
    }
    .sign_text {
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
      color: #fff;
    }
    .next_btn {
      margin-top: 28px;
      height: 40px;
      width: 100%;
      outline: none;
      border: none;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.33) 0%,
        rgba(255, 255, 255, 0.13) 100%
      );
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      // border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.51)
        )
        1 1;

      &.pre_btn {
        background: none;

        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.wx_download {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: #262626;
  background-image: url("https://static.zhongyiyf.com/17game/img/wx-guide0.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.9;
}

.big_video {
  font-size: 0px;
  width: 100%; /* 宽度为100% */
  position: relative;
  .img {
    width: 100%;
    z-index: 1;
  }
  .video {
    width: 100%;
    z-index: 0;
    &.load {
      z-index: 2;
    }
  }

  .new-service {
    min-width: 250px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.14rem;
    border-radius: 5px;
    bottom: 0.08rem;
  }
}
</style>
