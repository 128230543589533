<template>
  <div class="unionList ground-white">
    <!-- <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header> -->
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item prop="gameId" label="">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏名称"
          @change="changeGame"
        >
          <el-option
            v-for="item in gameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameServiceId" label="">
        <!-- <el-input v-model="roleForm.gameArea"></el-input> -->
        <el-select
          v-model="formInline.gameServiceId"
          clearable
          placeholder="请选择区服"
          @change="changeArea"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="formInline.unionId"
          clearable
          placeholder="请选择下级公会"
          @change="unionChange"
          v-if="false"
        >
          <el-option
            v-for="item in getUnionListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        label=""
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        sty="width: 220px"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <el-form-item prop="gameRoleId" label="">
        <el-select
          v-model="formInline.roleId"
          clearable
          placeholder="请输入角色"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          clearable
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <!-- 隐藏条件 -->
      <el-form-item v-if="false">
        <el-select v-model="formInline.platform" clearable placeholder="客户端">
          <el-option label="Android" :value="0"> </el-option>
          <el-option label="Ios" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.orderNo"
          clearable
          placeholder="精确搜索订单号"
        ></el-input>
      </el-form-item>
      <!-- 隐藏条件 -->
      <el-form-item v-if="false">
        <el-select
          v-model="formInline.payType"
          clearable
          placeholder="支付方式"
        >
          <el-option label="微信" :value="0"> </el-option>
          <el-option label="支付宝" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="orderStatus">
        <el-select
          v-model="formInline.orderStatus"
          clearable
          placeholder="订单状态"
        >
          <el-option
            v-for="item in statuses"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.page.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        充值总额：<span>{{ totalData.totalAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="orderNo"
        label="订单号"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="gameAccountId" label="游戏账号ID" width="130">
        <template slot-scope="scope">
          <RoleMark
            type="account"
            :rowData="scope.row"
            :colorMark="scope.row.gameAccountColorMark"
            :showData="scope.row.gameAccountId"
            :getList="getList"
            :hasPermission="true"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameRoleName"
        label="角色名"
        width="130"
      >
        <template slot-scope="scope">
          <RoleMark
            type="role"
            :rowData="scope.row"
            :colorMark="scope.row.gameRoleColorMark"
            :showData="scope.row.gameRoleName"
            :getList="getList"
            :hasPermission="true"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameServiceName" label="区服名">
      </el-table-column>
      <el-table-column
        align="center"
        prop="originGameServiceName"
        label="原区服"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏">
        <template slot-scope="scope">
          <GamePlatform
            :gameName="scope.row.gameName"
            :opPlatform="scope.row.opPlatform"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="payChannel" label="支付方式">
        <template slot-scope="scope">
          {{ scope.row.payChannel === 0 ? "微信" : "支付宝" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="amount" label="金额">
      </el-table-column>
      <el-table-column align="center" prop="ip" label="充值IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.ip }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="rechargeTime" label="充值时间">
      </el-table-column>
      <el-table-column align="center" prop="orderStatus" label="订单状态">
        <template slot-scope="scope">
          <div>
            <el-tag
              v-if="scope.row.orderStatus"
              :key="tagList[scope.row.orderStatus]"
              :type="tagList[scope.row.orderStatus]"
            >
              {{ statusDesc[scope.row.orderStatus] }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.inviterAccountName"
            :inviterAccountId="scope.row.inviterAccountLabel?.accountId"
            :departmentName="scope.row.inviterAccountLabel?.departmentName"
            :roleName="scope.row.inviterAccountLabel?.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="headerAccountName" label="团长">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.headerAccountName"
            :inviterAccountId="scope.row.headerAccountLabel?.accountId"
            :departmentName="scope.row.headerAccountLabel?.departmentName"
            :roleName="scope.row.headerAccountLabel?.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="查看">
        <template slot-scope="scope">
          <el-button type="text" @click="viewDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>
    <el-dialog
      title="充值记录"
      :visible="dialogVisible"
      class="Record"
      width="1200px"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :before-close="() => (dialogVisible = false)"
    >
      <rechargeRecord
        ref="HistoryRef"
        :isDone="true"
        height="60vh"
        :isInfo="true"
      ></rechargeRecord>

      <div slot="footer" align="center">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import rechargeRecord from "@/components/rechargeRecord.vue";
import { shortcuts } from "@/constants/index.js";
export default {
  components: { rechargeRecord },
  data() {
    return {
      loadingNew: false,
      rechargeStatusList: [
        { name: "支付失败", id: 200 },
        { name: "交货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      tagList: {
        100: "",
        200: "warning",
        300: "info",
        400: "success",
        500: "danger",
      },
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      getUnionListData: [],
      getGameListData: [],
      gameListData: [],
      areaListData: [],
      staffListData: [],
      gameRoleListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        payType: null,
        rechargeStatus: null,
        orderNo: null,
        gameAccountId: null,
        gameId: null,
        unionId: null,
        inviterAccountIdList: [],
        platform: null,
        startTime: "",
        gameArea: null,
        gameServiceId: null,
        endTime: "",
        orderStatus: 400,
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
        time: ["", ""],
        time1: ["", ""],
        time2: ["", ""],
        gameRoleId: "",
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
      statusDesc: {
        100: "创建中",
        200: "支付中",
        300: "发货中",
        400: "已完成",
        500: "已取消",
      },
      statuses: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "创建中",
          value: 100,
        },
        {
          label: "支付中",
          value: 200,
        },
        {
          label: "发货中",
          value: 300,
        },
        {
          label: "已完成",
          value: 400,
        },
        {
          label: "已取消",
          value: 500,
        },
      ],
      dialogVisible: false,
    };
  },
  mounted() {
    // this.getUnionList();
    this.getGameList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    getParams() {
      return {
        ...this.formInline,
        ...this.formInline.page,
        rechargeStartTime: this.formInline.time
          ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
          : "",
        rechargeEndTime: this.formInline.time
          ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
          : "",
      };
    },
    getUnionList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getUnionListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    unionChange() {
      console.log("unionChange", this.formInline.unionId);
      this.getAllInviter({ unionId: this.formInline.unionId });
      this.formInline.inviterAccountIdList = [];
    },
    exportList() {
      this.formInline.page.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .orderExportV2(this.getParams())
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.page.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .orderTotalV2(this.getParams())
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getList() {
      // 获取公会列表
      this.$api.dataApi
        .orderQueryV2(this.getParams())
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            // this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGameList() {
      this.$api.gameApi
        .querySubPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/data/union/commander/detail",
        query: {
          gameRoleId: e.gameRoleId,
        },
      });
    },

    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    changeGame(e) {
      console.log(e);
      // this.formInline.gameArea = null;
      this.formInline.gameServiceId = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            // accountIdList: [0],
            firstChargeInfo: true,
            roleName: query,
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.gameServiceId
              ? [parseInt(this.formInline.gameServiceId)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    changeArea(e) {
      console.log(e);
    },
    changeRole(e) {
      console.log(e);
      if (!e) {
        return;
      }
      this.$api.organizationApi
        .queryGameRole({
          // accountIdList: [0],
          firstChargeInfo: true,
          // gameAccountIdList: [0],
          gameIdList: this.formInline.gameId ? [this.formInline.gameId] : null,
          gameServerIdList: this.formInline.gameServiceId
            ? [parseInt(this.formInline.gameServiceId)]
            : null,
          needAccountInfo: true,
          roleIdList: [`${e}`],
          // roleName: "string",
        })
        .then((res) => {
          if (res.code === 0) {
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    viewDetail(data) {
      const params = {
        gameAccountId: data.gameAccountId,
        gameId: data.gameId,
        gameRoleId: data.gameRoleId,
      };
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.HistoryRef.refresh(params);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
.role_mark {
  // position: relative;
  // z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .role_mark_icon {
    position: relative;
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #fff;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border: 1px solid #b0b0b0;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: 1px solid #b0b0b0;
    }
  }

  .role_mark_icon.no_line {
    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      // border: 1px solid #b0b0b0;
      border: none;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: none;
      // border: 1px solid #b0b0b0;
    }
  }
  .opt_panel {
    position: absolute;
    z-index: 200;
    top: -30px;
    border: 1px solid #ddd;
    width: 240px;
    padding: 20px;
  }
}
.role_bd {
  display: flex;
  justify-content: flex-start;

  span {
    position: relative;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #d1d1d1;
    cursor: pointer;

    &:nth-of-type(1) {
      border-color: #fb5353;
    }
    &:nth-of-type(2) {
      border-color: #f49207;
    }
    &:nth-of-type(3) {
      border-color: #888;
    }
    &:nth-of-type(4) {
      border-color: #fff;
      &::before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 6px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
</style>
