import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=16a381dc&"
import script from "./Pagination.vue?vue&type=script&name=Pagination&lang=js&"
export * from "./Pagination.vue?vue&type=script&name=Pagination&lang=js&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=16a381dc&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.88.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports