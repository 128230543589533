<template>
  <div class="unionList ground-white">
    <el-page-header @back="backHandle"> </el-page-header>
    <br />
    <el-radio-group
      v-model="gameRoleId"
      size="mini"
      @input="getPage"
      v-if="$route.query.gameAccountId"
    >
      <el-radio-button
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.gameRoleId"
        >{{ item.gameRoleName }}</el-radio-button
      >
    </el-radio-group>
    <!-- {{ formInline }} -->
    <div class="data_bar">
      <p>
        角色：<span>{{ topData.roleName }}</span>
      </p>
      <p>
        游戏：<span>{{ topData.gameName }}</span>
      </p>
      <p>
        区服：<span>{{ topData.gameServiceName }}</span>
      </p>
      <p>
        等级：<span>{{ topData.roleLevel }}</span>
      </p>
      <p>
        今日登录：<span>{{ topData.loginInNum }}</span>
      </p>
      <p>
        今日在线时间：<span>{{ topData.onlineTime }}分钟</span>
      </p>
    </div>

    <loginRecord ref="LoginRef"></loginRecord>

    <rechargeRecord ref="HistoryRef"></rechargeRecord>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
import rechargeRecord from "@/components/rechargeRecord.vue";
import loginRecord from "@/components/loginRecord.vue";
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("gameAccountSecond");

export default {
  components: {
    rechargeRecord,
    loginRecord,
  },
  data() {
    return {
      value1: "",
      activeName: "first",
      tabList: [],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      gameRoleId: this.gameRoleId,
      midForm: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      bottomForm: {
        pageIndex: 1,
        pageSize: 5,
        totalElements: 0,
      },

      topData: {},
    };
  },
  mounted() {
    this.getRoleList();
    // this.getPermission();
    // this.getGameList();
    // this.getList();
  },
  methods: {
    backHandle() {
      this.$router.back();
      curdParams.setShow(true);
    },
    getPage() {
      const params = {
        gameAccountId: this.$route.query.gameAccountId,
        gameId: this.$route.query.gameId,
        gameRoleId: this.gameRoleId,
      };
      this.$nextTick(() => {
        this.getTop();
        this.$refs.LoginRef.refresh(params);
        this.$refs.HistoryRef.refresh(params);
      });
    },
    getRoleList() {
      this.$api.dataApi
        .gameAccountRoleListV1({
          gameAccountId: this.$route.query.gameAccountId,
          gameId: this.$route.query.gameId,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.tabList = result;
            this.gameRoleId = result[0].gameRoleId;
            this.getPage();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getTop() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleDetailSum({ gameRoleId: +this.gameRoleId })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.topData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },

    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    handleClick1(tab, event) {
      console.log(tab, event);
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },

    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  margin: 5px;
  border: 1px #dcdfe6 solid !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.unionList {
  .comment-area {
    overflow: hidden;
    border: 1px #999 dashed;
    padding: 30px 15px;
    width: fit-content;
    border-radius: 8px;
    .box-card {
      width: 450px;
      &:nth-of-type(odd) {
        // background: red;
      }
      &:nth-of-type(even) {
        margin-left: 500px;
      }
    }
  }
  .title-t {
    margin: 25px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15%;
    padding-left: 15px;
    span {
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
.tab-title {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 10px;
}
</style>
