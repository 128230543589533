<template>
  <el-pagination
    class="infinitePage"
    v-bind="$attrs"
    v-on="$listeners"
    :total="newTotal"
  ></el-pagination>
</template>
<script name="Pagination">
export default {
  props: {
    total: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  computed: {
    newTotal() {
      return 1000000;
    },
  },
};
</script>
<style lang="less">
.infinitePage {
  .number,
  .btn-quickprev,
  .btn-quicknext {
    display: none;
  }
  .number.active {
    display: inline;
  }
}
</style>
