<template>
  <div class="qr-code">
    <el-dialog
      title="游戏支付参数"
      :visible.sync="visible"
      width="1000px"
      @close="$emit('close')"
      class="code-dialog"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        label-width="130px"
        size="small"
      >
        <el-form-item label="支付宝渠道appId" prop="appId">
          <el-input v-if="isEdit" v-model="form.appId" clearable></el-input>
          <span class="text" v-else>{{ form.appId || "--" }}</span>
        </el-form-item>
        <el-form-item label="回调地址" prop="deliverUrl">
          <el-input
            v-if="isEdit"
            v-model="form.deliverUrl"
            clearable
          ></el-input>
          <span class="text" v-else>{{ form.deliverUrl || "--" }}</span>
        </el-form-item>
        <el-form-item label="渠道方公钥" prop="publicKey">
          <el-input v-if="isEdit" v-model="form.publicKey" clearable></el-input>
          <span class="text" v-else>{{ form.publicKey || "--" }}</span>
        </el-form-item>
        <el-form-item label="登录验签私钥" prop="privateKey">
          <el-input
            v-if="isEdit"
            v-model="form.privateKey"
            clearable
          ></el-input>
          <span class="text" v-else>{{ form.privateKey || "--" }}</span>
        </el-form-item>

        <el-form-item>
          <el-popover
            v-if="isEdit"
            placement="bottom"
            width="260"
            v-model="saveVisible"
            trigger="manual"
          >
            <p>检测到参数已被改动，请问是否需要保存这些更改？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="saveVisible = false"
                >取消</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="(saveVisible = false), save()"
                >确定</el-button
              >
            </div>
            <el-button
              type="primary"
              @click="submitForm('form')"
              :loading="saveLoading"
              slot="reference"
              >保 存</el-button
            >
          </el-popover>
          <el-button v-else type="primary" @click="editClick">编 辑</el-button>
          <el-button
            @click="(isEdit = false), (form = formCp)"
            v-if="isEdit"
            style="margin-left: 10px"
            >取消编辑</el-button
          >
          <el-button @click="$emit('close')" v-else>关&emsp;闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "loginPage",
  props: {
    data: {
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: true,
      isEdit: false,
      saveLoading: false,
      ruleForm: {
        gameId: [
          {
            required: true,
            message: "请输入游戏ID",
          },
        ],
        publicKey: [
          {
            required: true,
            message: "请输入公钥",
          },
        ],
        privateKey: [
          {
            required: true,
            message: "请输入私钥",
          },
        ],
        appId: [
          {
            required: true,
            message: "请输入appId",
          },
        ],
        deliverUrl: [
          {
            required: true,
            message: "请输入回调地址",
          },
        ],
      },
      form: {
        gameId: "",
        publicKey: "",
        privateKey: "",
        appId: "",
        deliverUrl: "",
        channelId: null,
      },
      formCp: {},
      saveVisible: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isFormEdit() {
      let show = false;
      for (let key in this.form) {
        if (this.form[key] !== this.formCp[key]) {
          show = true;
          break;
        }
      }
      return show;
    },
  },
  methods: {
    init() {
      this.form.gameId = this.data.id;
      this.getConfig();
    },
    getConfig() {
      this.$api.organizationApi
        .getChannel({
          gameId: this.data.id,
        })
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res);
          if (res.code === 0 && res.data) {
            this.form = { ...this.form, ...res.data };
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editClick() {
      this.isEdit = true;
      this.formCp = { ...this.form };
    },
    async submitForm(formName) {
      try {
        await this.$refs[formName].validate();
        // 判断是否有修改
        if (this.isFormEdit) {
          this.saveVisible = true;
          // this.save();
          return;
        } else {
          this.isEdit = false;
        }
      } catch (error) {
        console.error("🚀 ~ submitForm ~ error:", error);
      }
    },
    save() {
      this.saveLoading = true;
      const api = this.form.channelId ? "updateChannel" : "addChannel";
      this.$api.organizationApi[api](this.form)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.isEdit = false;
            this.saveLoading = false;
            this.getConfig();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.code-dialog {
  .text {
    color: #999;
    line-height: 20px;
    display: flex;
    padding-top: 6px;
  }
}
.qrcode {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.btn-container {
  display: flex;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  box-sizing: border-box;
  overflow: auto;
}

@media (max-width: 440px) {
  ::v-deep .el-dialog {
    width: 350px !important;
  }
}
</style>
