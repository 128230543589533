<template>
  <div class="unionList ground-white">
    <el-page-header @back="backHandle" />
    <br />
    <div class="data_bar">
      <p>
        帐号ID：<span>{{ totalData.accountId }}</span>
      </p>
      <p>
        角色：<span>{{ totalData.gameRoleName }}</span>
      </p>
      <p>
        游戏：<span>{{ totalData.gameName }}</span>
      </p>
      <p>
        区服：<span>{{ totalData.gameServiceName }}</span>
      </p>
      <p>
        等级：<span>{{ totalData.roleLevel }}</span>
      </p>
      <p>
        今日登陆次数：<span>{{ totalData.loginCount }}</span>
      </p>
      <p>
        今日在线时间(分钟)：<span>{{ totalData.onlineDuration }}</span>
      </p>
      <p>
        曾用名：<span
          v-for="(item, index) in totalData.oldNameList"
          :key="index"
        >
          {{ item }},
        </span>
      </p>
      <p>
        充值总额：<span>{{ totalData.chargeTotalAmount }}</span>
      </p>
    </div>
    <loginRecord ref="LoginRef"></loginRecord>
    <rechargeRecord ref="HistoryRef"></rechargeRecord>
  </div>
</template>
<script>
import rechargeRecord from "@/components/rechargeRecord.vue";
import loginRecord from "@/components/loginRecord.vue";
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("gameRole");
export default {
  components: {
    rechargeRecord,
    loginRecord,
  },
  data() {
    return {
      totalData: 0,
    };
  },
  mounted() {
    this.getRoleDetail();
  },
  methods: {
    backHandle() {
      this.$router.back();
      curdParams.setShow(true);
    },
    getRoleDetail() {
      const params = {
        gameAccountId: this.$route.query.gameAccountId,
        gameId: this.$route.query.gameId,
        gameRoleId: this.$route.query.gameRoleId,
      };
      this.$api.dataApi
        .gameRoleDetail(params)

        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : 0;
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }

          this.$nextTick(() => {
            this.$refs.LoginRef.refresh(params);
            this.$refs.HistoryRef.refresh(params);
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
