<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          filterable
          placeholder="请选择游戏"
          @change="changeGame"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.gameServerId"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in subGameListData"
            :key="item.serviceId"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.roleName"
          clearable
          placeholder="游戏角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          clearable
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.frozenStatus"
          clearable
          placeholder="请选择角色状态"
        >
          <el-option
            v-for="item in frozenStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏名称">
      </el-table-column>
      <el-table-column align="center" prop="gameServerName" label="区服">
      </el-table-column>
      <el-table-column align="center" prop="roleId" label="游戏角色ID">
      </el-table-column>
      <el-table-column align="center" prop="roleName" label="角色名字">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="rechargeCreateTime"
        label="首次充值时间"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
      <el-table-column align="center" prop="applyAccountName" label="申请人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="totalRechargeAmount"
        label="累计充值"
      >
      </el-table-column>
      <el-table-column align="center" prop="frozenStatus" label="角色状态">
        <template slot-scope="scope">
          <span>{{ scope.row.frozenStatus === 1 ? "冻结" : "正常" }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="详情" width="100">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.frozenStatus === 0"
            type="text"
            size="small"
            @click="handleClick(scope.row, 1)"
            >冻结</el-button
          >
          <el-button
            v-if="scope.row.frozenStatus === 1"
            type="text"
            size="small"
            @click="handleClick(scope.row, 0)"
            >解冻</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      getGameListData: [],
      staffListData: [],
      loading: false,
      formInline: {
        roleName: null,
        gameAccountId: null,
        gameId: null,
        frozenStatus: null,
        pageIndex: 1,
        pageSize: 20,
      },
      tableData: [],
      totalData: {},
      subGameListData: [],
      frozenStatusList: [
        {
          label: "正常",
          value: 0,
        },
        {
          label: "冻结",
          value: 1,
        },
      ],
    };
  },
  mounted() {
    this.getGameList();
    this.getList();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .queryResourceAccountList({
          ...this.formInline,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.supportApi
        .getGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(val) {
      this.$api.supportApi
        .getGameServiceList({ pageIndex: 1, pageSize: 1000, gameId: val })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.data ? res.data?.data : [];
            this.subGameListData = result;
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(val, type) {
      const desc = ["解冻", "冻结"];
      this.$confirm(`确定要${desc[type]}该账号吗？`, "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        const { roleId, gameAccountId, gameId } = val;
        const params = {
          roleId,
          frozenStatus: type,
          gameAccountId,
          gameId,
        };
        this.$api.supportApi.frozenMemberV2({ ...params }).then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
