<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="游戏">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        游戏数：<span>{{ totalData.gameNum }}</span>
      </p>
      <p>
        角色创建数：<span>{{ totalData.roleCreateNum }}</span>
      </p>
      <p>
        角色活跃数：<span>{{ totalData.roleActiveNum }}</span>
      </p>
      <p>
        充值角色数：<span>{{ totalData.chargeRoleNum }}</span>
      </p>
      <p>
        充值总额：<span>{{ totalData.chargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="gameName" label="游戏">
      </el-table-column>
      <el-table-column align="center" prop="roleCreateNum" label="角色创建数">
      </el-table-column>
      <el-table-column align="center" prop="roleActiveNum" label="角色活跃数">
      </el-table-column>
      <el-table-column align="center" prop="chargeRoleNum" label="角色充值数">
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值总额">
      </el-table-column>
      <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >区服详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("unionPartServices");
export default {
  data() {
    return {
      getGameListData: [],
      loading: false,
      formInline: {
        gameId: null,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    if (curdParams.getShow) {
      curdParams.setShow(false);
      let params = curdParams.getParams;
      if (params) {
        this.formInline = { ...this.formInline, ...params };
      }
    }
    this.getGameList();
    this.getList();
    this.getListSum();
  },
  methods: {
    exportList() {
      this.formInline.pageSize = 50000;
      this.$api.dataApi
        .gameStatisExport({
          ...this.formInline,
        })
        .then((res) => {
          this.formInline.pageSize = 20;
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      this.$api.dataApi
        .gameStatisTotal({
          ...this.formInline,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let params = { ...this.formInline };
      curdParams.setParams(params);
      this.$api.dataApi
        .gameStatisQuery({
          ...this.formInline,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            // this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .querySubPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      const findGame = this.getGameListData.find((item) => {
        return item.gameName === e.gameName;
      });
      this.$router.push({
        path: "/data/union/area/role/union",
        query: {
          gameId: findGame.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
