<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->

    <el-form :inline="true" :model="formInline" label-width="90px">
      <el-form-item label="角色名字">
        <el-input
          v-model="formInline.roleName"
          clearable
          placeholder="请输入角色名字"
        ></el-input>
      </el-form-item>
      <el-form-item label="游戏账号ID">
        <el-input
          v-model="formInline.gameAccountId"
          clearable
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
          v-model="formInline.realName"
          clearable
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色ID">
        <el-input
          v-model="formInline.roleId"
          clearable
          placeholder="请输入角色ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input
          v-model="formInline.mobile"
          clearable
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证">
        <el-input
          v-model="formInline.idCard"
          clearable
          placeholder="请输入身份证号"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="accountId"
        label="注册账号ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
      ></el-table-column>

      <el-table-column
        align="center"
        prop="gameName"
        label="游戏名称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameServerName"
        label="区服"
      ></el-table-column>
      <el-table-column align="center" prop="roleId" label="游戏角色ID">
      </el-table-column>
      <el-table-column
        align="center"
        prop="roleName"
        label="角色名字"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="roleName"
        label="曾用名字"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>
            {{ (scope.row.oldRoleNameList || []).join(",") }}
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="createTime" label="创建时间">
      </el-table-column>

      <el-table-column align="center" prop="mobile" label="手机号码">
        <template slot-scope="scope">
          <mobileShow
            :list="scope.row.mobileChangeList"
            :text="scope.row.mobile"
          ></mobileShow>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="realName" label="姓名">
      </el-table-column>
      <el-table-column align="center" prop="idCard" label="身份证号码">
      </el-table-column>
      <el-table-column align="center" prop="opIp" label="创建IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.opIp }}</div>
            <div>{{ scope.row.ipArea }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
import mobileShow from "./mobileShow.vue";

export default {
  components: {
    mobileShow,
  },
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        roleName: null,
        gameAccountId: null,
        realName: null,
        roleId: null, // 角色ID
        mobile: null, // 用户手机号
        idCard: null, // 用户身份证
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    this.getList();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .resourceManage({
          ...this.formInline,
        })
        .then((res) => {
          console.log("resourceManage", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
