var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"Record",attrs:{"title":"新增封禁","visible":true,"width":"400px","top":"30vh","close-on-click-modal":false,"modal-append-to-body":true,"before-close":() => _vm.$emit('close')}},[_c('el-form',{ref:"addFormRef",attrs:{"model":_vm.confirmForm,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"封禁目标","prop":"targetType","rules":[
        {
          required: true,
          message: '请选择封禁目标',
        },
      ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.confirmForm.targetType),callback:function ($$v) {_vm.$set(_vm.confirmForm, "targetType", $$v)},expression:"confirmForm.targetType"}},[_c('el-option',{attrs:{"label":"IP","value":"1"}}),_c('el-option',{attrs:{"label":"设备号","value":"2"}})],1)],1),_c('el-form-item',{attrs:{"label":"封禁内容","prop":"targetId","required":true,"rules":[
        {
          required: true,
          message: '请输入封禁内容',
        },
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.confirmForm.targetId),callback:function ($$v) {_vm.$set(_vm.confirmForm, "targetId", $$v)},expression:"confirmForm.targetId"}})],1),_c('el-form-item',{attrs:{"label":"备注","required":false}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.confirmForm.remark),callback:function ($$v) {_vm.$set(_vm.confirmForm, "remark", $$v)},expression:"confirmForm.remark"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addSumbit}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }