import { render, staticRenderFns } from "./GamePlatform.vue?vue&type=template&id=44eb81fc&scoped=true&"
import script from "./GamePlatform.vue?vue&type=script&lang=js&"
export * from "./GamePlatform.vue?vue&type=script&lang=js&"
import style0 from "./GamePlatform.vue?vue&type=style&index=0&id=44eb81fc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.88.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44eb81fc",
  null
  
)

export default component.exports