import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
const gameApi = {
  // 资源上传接口
  uploadResourc() {
    return `${base.sq}/gameUpload/upload`;
  },
  uploadFile(params) {
    return axios.post(`${base.sq}/gameUpload/upload`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    });
  },
  // ----------------   超管    ----------------

  //获取更新包列表
  queryUpdatePackage(params) {
    return axios.post(`${base.sq}/gameUpload/query`, params);
  },
  //新增更新包列表
  addUpdatePackage(params) {
    return axios.post(`${base.sq}/gameUpload/create`, params);
  },
  // 删除更新包列表
  deleteUpdatePackage(params) {
    return axios.post(`${base.sq}/gameUpload/delete`, params);
  },

  // 获取游戏包列表
  queryPackage(params) {
    return axios.post(`${base.sq}/game/query`, params);
  },
  // 新增游戏包
  addPackage(params) {
    return axios.post(`${base.sq}/game/create`, params);
  },
  // 修改游戏包
  updatePackage(params) {
    return axios.post(`${base.sq}/game/update`, params);
  },
  // 上架/下架游戏包
  changeStatusPackage(params) {
    return axios.post(`${base.sq}/game/status`, params);
  },
  // 删除游戏包
  deletePackage(params) {
    return axios.post(`${base.sq}/game/delete`, params);
  },
  // 查询游戏详情
  queryGameDetail(params) {
    return axios.post(`${base.sq}/game/detail`, params);
  },
  // 修改游戏详情
  updateGameDetail(params) {
    return axios.post(`${base.sq}/game/detailUpdate`, params);
  },
  // 查询扶持比例
  queryGameSupportConfig(params) {
    return axios.post(`${base.sq}/gameSupportConfig/query`, params);
  },
  // 修改扶持比例
  updateGameSupportConfig(params) {
    return axios.post(`${base.sq}/gameSupportConfig/update`, params);
  },
  // 查询结算比例
  queryMoneyConfig(params) {
    return axios.post(`${base.sq}/unionGameSettleConfig/query`, params);
  },
  // 修改结算比例
  updateMoneyConfig(params) {
    return axios.post(`${base.sq}/unionGameSettleConfig/update`, params);
  },
  // 查询下级分包列表
  querySubPackage(params) {
    return axios.post(`${base.sq}/unionGame/query`, params);
  },
  // 查询游戏列表
  queryGameListMap(params) {
    return axios.post(`${base.sq}/unionGame/queryGame`, params);
  },
  queryAdminGame(params) {
    return axios.post(`${base.sq}/game/queryGame`, params);
  },
  // 修改结算比例
  updateSubPackage(params) {
    return axios.post(`${base.sq}/gameSettleConfig/update`, params);
  },
  //查询申请游戏列表
  queryApplyGame(params) {
    return axios.post(`${base.sq}/unionGame/queryApply`, params);
  },
  // 同意申请
  doApply(params) {
    return axios.post(`${base.sq}/unionGame/audit`, params);
  },
  // ----------------   公会    ----------------
  // 申请游戏
  applyUnionGame(params) {
    return axios.post(`${base.sq}/game/apply`, params);
  },
  // 获取推广链接
  getShareUrl(params) {
    return axios.post(`${base.sq}/unionGame/spread`, params);
  },
  // 获取推广页区服
  getShareArea(params) {
    return axios.post(`${base.sq}/h5game/gameService`, params);
  },
  // 获取推广页下载链接
  getSharePackage(params) {
    return axios.post(`${base.sq}/h5game/download`, params);
  },

  // ------------------大屏：数据统计-----------------
  getUserStatis(params) {
    return axios.post(`${base.sq}/userStatis/query`, params);
  },
  getUserStatisList(params) {
    return axios.post(`${base.sq}/userStatis/list`, params);
  },
  // 分包
  unionGamePack(params) {
    return axios.post(`${base.sq}/unionGame/pack`, params);
  },
  // 获取分包状态值
  queryPackStatus(params) {
    return axios.post(`${base.sq}/unionGame/packInfo`, params);
  },
  // 获取公会游戏列表
  queryGameList(params) {
    return axios.post(`${base.sq}/game/list`, params);
  },

  getPageConfigInfo(params) {
    return axios.post(`${base.sq}/h5game/api/getInvitePageInfo`, params);
  },

  refreshPackInviteCode(params) {
    return axios.post(`${base.sq}/unionGame/refreshPackInviteCode`, params);
  },
};

export default gameApi;
