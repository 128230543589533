<template>
  <el-dialog
    title="新增封禁"
    :visible="true"
    class="Record"
    width="400px"
    top="30vh"
    :close-on-click-modal="false"
    :modal-append-to-body="true"
    :before-close="() => $emit('close')"
  >
    <el-form ref="addFormRef" :model="confirmForm" label-width="80px">
      <el-form-item
        label="封禁目标"
        prop="targetType"
        :rules="[
          {
            required: true,
            message: '请选择封禁目标',
          },
        ]"
      >
        <el-select
          v-model="confirmForm.targetType"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option label="IP" value="1"></el-option>
          <el-option label="设备号" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="封禁内容"
        prop="targetId"
        :required="true"
        :rules="[
          {
            required: true,
            message: '请输入封禁内容',
          },
        ]"
      >
        <el-input v-model="confirmForm.targetId" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="备注" :required="false">
        <el-input v-model="confirmForm.remark" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="addSumbit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      addVisible: false,
      confirmForm: {
        remark: "",
        targetType: "",
        targetId: "",
        type: 1,
      },
    };
  },
  mounted() {},
  methods: {
    addSumbit() {
      this.$refs.addFormRef?.validate((valid) => {
        if (valid) {
          this.$api.supportApi
            .createBanRecord(this.confirmForm)
            .then((res = {}) => {
              const { code, msg } = res;
              if (code === 0) {
                this.$message.success("添加成功");
                this.$emit("close");
                this.$emit("submit");
              } else {
                msg && this.$message.error(msg);
              }
            })
            .catch(() => {
              this.$message.error("添加失败");
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .targetId .el-select .el-input {
  width: 90px;
}

::v-deep .el-table__header th {
  background: #f7f9fd;
  font-weight: bold;
  height: 40px;
  padding: 0;
  color: #95a3bb;
}

.el-pagination {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
</style>
