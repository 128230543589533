var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_vm._m(0),_c('div',{staticClass:"protrol"},[_c('el-dialog',{attrs:{"title":"平台协议","visible":_vm.dialogVisible,"width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"protocol"},[_c('Protocol')],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})])],1),_c('div',{staticClass:"tip"},[_c('el-dialog',{attrs:{"width":"300px","visible":_vm.centerDialogVisible},on:{"update:visible":function($event){_vm.centerDialogVisible=$event}}},[_c('span',{staticStyle:{"font-size":"16px","color":"#666","text-align":"center","width":"100%","display":"block","margin-bottom":"-20px"}},[_vm._v(_vm._s(_vm.contact[_vm.platformType]))]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticStyle:{"background":"#27282c","cursor":"pointer","width":"90%","margin":"10px auto","height":"42px","text-align":"center","color":"white","line-height":"42px","border-radius":"5px"},on:{"click":function($event){_vm.centerDialogVisible = false}}},[_vm._v(" 确认 ")])])])],1),_c('div',{staticClass:"container open"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/avator.png")}}),_c('div',{staticClass:"form-set"},[_c('div',{staticClass:"form-group"},[_c('div',{class:{
            'form-label': true,
            'on-focus': _vm.accountNameFocus || _vm.ruleForm.accountName,
          }},[_vm._v(" 用户名 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruleForm.accountName),expression:"ruleForm.accountName"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.ruleForm.accountName)},on:{"focus":() => (_vm.accountNameFocus = true),"blur":() => (_vm.accountNameFocus = false),"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submitForm.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruleForm, "accountName", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('div',{class:{
            'form-label': true,
            'on-focus': _vm.accountPasswordFocus || _vm.ruleForm.accountPassword,
          }},[_vm._v(" 密码 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruleForm.accountPassword),expression:"ruleForm.accountPassword"}],staticClass:"form-control",attrs:{"type":"password"},domProps:{"value":(_vm.ruleForm.accountPassword)},on:{"focus":() => (_vm.accountPasswordFocus = true),"blur":() => (_vm.accountPasswordFocus = false),"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submitForm.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruleForm, "accountPassword", $event.target.value)}}})]),_c('button',{staticClass:"btn",attrs:{"disabled":_vm.loading},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.loading ? "登录中..." : "登入")+" ")])]),_c('div',{staticClass:"loginProtrol flex"},[_c('div',{staticClass:"loginProtrolLeft"},[_c('el-checkbox',{staticStyle:{"margin-bottom":"2.5px","margin-right":"5px"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),_c('span',[_vm._v("请先同意")]),_c('el-button',{staticStyle:{"font-weight":"normal","color":"#ba4d4f"},attrs:{"type":"text"},on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v("《平台协议》")])],1),_c('div',{staticClass:"forgotlink",on:{"click":_vm.open}},[_vm._v("忘记密码?")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top flex"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/avator.png")}}),_c('div',{staticClass:"title"},[_vm._v("Game最强王者联盟")])])
}]

export { render, staticRenderFns }