import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const dataApi = {
  /**
   * @NAME {公会端所有接口}
   *
   */
  // 数据汇总-查询汇总
  inviterStatisTotal(params) {
    return axios.post(`${base.sq}/data/inviterStatis/total`, params);
  },
  // 数据汇总-查询查询数据列表
  inviterStatisQuery(params) {
    return axios.post(`${base.sq}/data/inviterStatis/query`, params);
  },
  inviterStatisExport(params) {
    return axios.post(`${base.sq}/data/inviterStatis/export`, params, {
      responseType: "blob",
    });
  },
  inviterStatisQueryA(params) {
    return axios.post(`${base.sq}/data/inviterStatis/queryDetail`, params);
  },
  inviterStatisExportA(params) {
    return axios.post(`${base.sq}/data/inviterStatis/totalDetail`, params);
  },
  inviterStatisExportAA(params) {
    return axios.post(`${base.sq}/data/inviterStatis/exportDetail`, params, {
      responseType: "blob",
    });
  },
  // 角色区服
  roleAreaTotal(params) {
    return axios.post(`${base.sq}/data/roleArea/total`, params);
  },
  roleAreaQuery(params) {
    return axios.post(`${base.sq}/data/roleArea/query`, params);
  },
  roleAreaExport(params) {
    return axios.post(`${base.sq}/data/roleArea/export`, params, {
      responseType: "blob",
    });
  },
  roleAreaTotalA(params) {
    return axios.post(`${base.sq}/data/roleArea/totalDetail`, params);
  },
  roleAreaQueryA(params) {
    return axios.post(`${base.sq}/data/roleArea/queryDetail`, params);
  },
  roleAreaExportA(params) {
    return axios.post(`${base.sq}/data/roleArea/export`, params, {
      responseType: "blob",
    });
  },

  // 游戏区服
  gameStatisTotal(params) {
    return axios.post(`${base.sq}/data/gameStatis/total`, params);
  },
  gameStatisQuery(params) {
    return axios.post(`${base.sq}/data/gameStatis/query`, params);
  },

  gameStatisExport(params) {
    return axios.post(`${base.sq}/data/gameStatis/export`, params, {
      responseType: "blob",
    });
  },
  queryUnionStatisList(params) {
    return axios.post(
      `${base.sq}/data/gameStatis/queryUnionStatisList`,
      params
    );
  },
  queryUnionTotalStatis(params) {
    return axios.post(
      `${base.sq}/data/gameStatis/queryUnionTotalStatis`,
      params
    );
  },
  exportUnionStatis(params) {
    return axios.post(`${base.sq}/data/gameStatis/exportUnionStatis`, params, {
      responseType: "blob",
    });
  },

  // 游戏角色
  gameRoleQuery(params) {
    return axios.post(`${base.sq}/data/gameRole/queryDetail`, params);
  },
  gameRoleExport(params) {
    return axios.post(`${base.sq}/data/gameRole/export`, params, {
      responseType: "blob",
    });
  },
  gameRoleQueryA(params) {
    return axios.post(`${base.sq}/data/gameRole/queryDetail`, params);
  },
  gameRoleExportA(params) {
    return axios.post(`${base.sq}/data/gameRole/export`, params, {
      responseType: "blob",
    });
  },

  gameRoleTotal(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/total`, params);
  },
  gameRoleQueryNew(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/query`, params);
  },
  gameRoleExportNew(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/export`, params, {
      responseType: "blob",
    });
  },
  gameRoleDetail(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/detail`, params);
  },
  gameRoleLoginList(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/queryLoginList`, params);
  },

  exportGameRoleLoginList(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/exportLoginList`, params, {
      responseType: "blob",
    });
  },
  // 有效角色
  ruleRoleQuery(params) {
    return axios.post(
      `${base.sq}/data/gameRole/getEffectiveGameRoleStatistic/page`,
      params
    );
  },
  ruleRoleExport(params) {
    return axios.post(
      `${base.sq}/data/gameRole/getEffectiveGameRoleStatistic/export`,
      params,
      {
        responseType: "blob",
      }
    );
  },
  ruleRoleQueryA(params) {
    return axios.post(
      `${base.sq}/data/gameRole/union/getEffectiveGameRoleStatistic/page`,
      params
    );
  },
  ruleRoleExportA(params) {
    return axios.post(
      `${base.sq}/data/gameRole/union/getEffectiveGameRoleStatistic/export`,
      params,
      {
        responseType: "blob",
      }
    );
  },
  // 游戏账户
  gameAccountQuery(params) {
    return axios.post(
      `${base.sq}/data/gameAccount/getGameAccountStatistic/page`,
      params
    );
  },
  gameAccountExport(params) {
    return axios.post(
      `${base.sq}/data/gameAccount/getGameAccountStatistic/export`,
      params,
      {
        responseType: "blob",
      }
    );
  },
  gameAccountQueryA(params) {
    return axios.post(
      `${base.sq}/data/gameAccount/union/gameAccountSum/page`,
      params
    );
  },
  gameAccountExportA(params) {
    return axios.post(
      `${base.sq}/data/gameAccount/union/gameAccountSum/export`,
      params,
      {
        responseType: "blob",
      }
    );
  },
  // 获取推广员
  getAllInviter(params) {
    return axios.post(`${base.sq}/department/queryInviter`, params);
  },
  // 查询工会所有部门和推广列表
  getAllInviterV2(params) {
    return axios.post(`${base.sq}/department/queryAllInviter`, params);
  },
  // 充值明细
  orderDetailQuery(params) {
    return axios.post(`${base.sq}/data/user/order/detail`, params);
  },
  orderDetailQueryA(params) {
    return axios.post(`${base.sq}/data/union/order/sum/page`, params);
  },
  orderDetailSum(params) {
    return axios.post(`${base.sq}/data/user/order/sum`, params);
  },
  orderDetailExport(params) {
    return axios.post(`${base.sq}/data/user/order/detail/export`, params, {
      responseType: "blob",
    });
  },

  // 任务汇总
  taskSumQueryA(params) {
    return axios.post(`${base.sq}/task/statis/day/sum`, params);
  },
  taskSumQuery(params) {
    return axios.post(`${base.sq}/task/statis/day/query`, params);
  },
  taskSumSum(params) {
    return axios.post(`${base.sq}/task/statis/day/subSum`, params);
  },
  getTaskSum(params) {
    return axios.post(`${base.sq}/game/query`, params);
  },
  taskSumExport(params) {
    return axios.post(`${base.sq}/data/user/order/detail/export`, params, {
      responseType: "blob",
    });
  },

  // 带团角色 /data/taskRole/taskRoleSumList
  taskRoleQuery(params) {
    return axios.post(`${base.sq}/data/taskRole/query`, params);
  },
  taskRoleExport(params) {
    return axios.post(`${base.sq}/data/taskRole/export`, params, {
      responseType: "blob",
    });
  },
  taskRoleQueryA(params) {
    return axios.post(`${base.sq}/data/taskRole/taskRoleSumList`, params);
  },
  // 带团角色详情
  taskRoleDetailQuery(params) {
    return axios.post(`${base.sq}/user/report/queryRoleDetailList`, params);
  },
  taskRoleDetailSum(params) {
    return axios.post(`${base.sq}/user/report/statis`, params);
  },
  taskRoleDetailCommentList(params) {
    return axios.post(`${base.sq}/user/note/query`, params);
  },
  taskRoleDetailComment(params) {
    return axios.post(`${base.sq}/user/note/create`, params);
  },

  logout(params) {
    return axios.post(`${base.sq}/account/loginOut`, params);
  },
  //获取导航
  tree(params) {
    return axios.post(`${base.sq}/resource/queryMenu`, params);
  },
  //获取页面基础配置
  treeDetail(params) {
    return axios.post(`${base.sq}/resource/queryMenuInterface`, params);
  },
  //获取账户信息
  updateAccountInfo(params) {
    return axios.post(`${base.sq}/account/updateInfo`, params);
  },
  //获取账户信息
  queryAccount(params) {
    return axios.post(`${base.sq}/account/queryAccount`, params);
  },
  //查询账户信息
  queryAccountInfo(params) {
    return axios.post(`${base.sq}/account/query`, params);
  },
  //修改密码
  updatePassword(params) {
    return axios.post(`${base.sq}/account/updatePassword`, params);
  },
  // 新游戏角色明细数据汇总
  inviterStaticsTotal(params) {
    return axios.post(`${base.sq}/data/gameRole/sum`, params);
  },
  // 新有效角色明细数据汇总
  userStaticsTotal(params) {
    return axios.post(
      `${base.sq}/data/gameRole/getEffectiveGameRoleStatistic/sum`,
      params
    );
  },
  // 新充值明细数据汇总
  userOrderTotal(params) {
    return axios.post(`${base.sq}/data/user/order/sum`, params);
  },
  canShowOrderTotal(params) {
    return axios.post(`${base.sq}/common/checkAmountShow`, params);
  },
  // 新带团角色数据汇总
  taskRoleTotal(params) {
    return axios.post(`${base.sq}/data/taskRole/sum`, params);
  },

  // 角色区服统计
  queryUnion(params) {
    return axios.post(`${base.sq}/data/roleArea/queryUnion`, params);
  },

  // 权限/union/v1/resource/queryMenuInterface
  getBtnPermission(params) {
    return axios.post(`${base.sq}/resource/queryMenuInterface`, params);
  },

  // 数据汇总-查询汇总
  inviterStatisTotalV2(params) {
    return axios.post(`${base.sq}/v2/data/inviterStatis/total`, params);
  },
  // 数据汇总-查询查询数据列表
  inviterStatisQueryV2(params) {
    return axios.post(`${base.sq}/v2/data/inviterStatis/query`, params);
  },
  inviterStatisExportV2(params) {
    return axios.post(`${base.sq}/v2/data/inviterStatis/export`, params, {
      responseType: "blob",
    });
  },
  // 游戏账号列表-24.11.14
  gameAccountQueryV1(params) {
    return axios.post(`${base.sq}/data/gameAccount/query`, params);
  },
  gameAccountTotalV1(params) {
    return axios.post(`${base.sq}/data/gameAccount/total`, params);
  },
  gameAccountExportV1(params) {
    return axios.post(`${base.sq}/data/gameAccount/export`, params, {
      responseType: "blob",
    });
  },
  gameAccountRoleListV1(params) {
    return axios.post(`${base.sq}/data/gameAccount/queryRoleList`, params);
  },
  // 充值明细
  orderQueryV2(params) {
    return axios.post(`${base.sq}/v2/data/user/order/query`, params);
  },
  orderTotalV2(params) {
    return axios.post(`${base.sq}/v2/data/user/order/total`, params);
  },
  orderExportV2(params) {
    return axios.post(`${base.sq}/v2/data/user/order/export`, params, {
      responseType: "blob",
    });
  },
  // 游戏角色详情-充值记录
  queryRechargeListV2(params) {
    return axios.post(`${base.sq}/v2/data/gameRole/queryRechargeList`, params);
  },
  // 游戏角色详情-充值记录导出
  exportRechargeListV2(params) {
    return axios.post(
      `${base.sq}/v2/data/gameRole/exportRechargeList`,
      params,
      {
        responseType: "blob",
      }
    );
  },
};

export default dataApi;
