<template>
  <div class="game-apply ground-white">
    <div class="groupTree">
      <div class="grouphead">
        <el-page-header
          @back="$router.back()"
          content=""
          style="margin-bottom: 15px"
        >
        </el-page-header>
        <div class="btn1">
          <el-button
            type="primary"
            @click="
              dialogFormVisible = true;
              editDialogShow = false;
              queryForm.departmentName = '';
              queryForm.parentDepartmentId = '';
              clearSelected();
            "
            >新建部门</el-button
          >
          <el-button
            type="primary"
            :disabled="editUnionId === topId || !editUnionId"
            @click="openEditDialog"
            >编辑部门</el-button
          >
        </div>
        <el-button
          type="primary"
          :disabled="!lastDepartmentData"
          @click="deleteDepartment"
          >删除部门</el-button
        >
        <div class="inp">
          <el-input
            v-model="filterText"
            placeholder="请输入部门名称"
          ></el-input>
        </div>
      </div>
      <div class="grouplist">
        <strong>组织机构</strong>
        <el-tree
          v-loading="$store.state.loadingStatus"
          :data="data"
          :props="defaultProps"
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
          :default-expand-all="false"
          highlight-current
          ref="tree"
        ></el-tree>
      </div>
    </div>
    <div class="staffList">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="昵称">
          <el-input v-model="formInline.nickName" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item label="岗位">
          <el-select clearable v-model="formInline.roleId" placeholder="请选择">
            <el-option
              v-for="item in roleOption"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formInline.status" placeholder="状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              formInline.pageIndex = 1;
              getStaffList();
            "
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="
              dialogFormVisibleNew = true;
              editDialogShowNew = false;
              resetForm('roleForm');
            "
            >新建人员</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        v-loading="$store.state.loadingStatus"
        :data="tableData"
        :border="true"
        style="width: 100%"
        :header-cell-style="
          () =>
            'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
        "
      >
        <el-table-column
          align="center"
          prop="nickName"
          label="昵称"
          width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="departmentName"
          label="部门"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="岗位"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column align="center" prop="accountId" label="ID" width="70">
        </el-table-column>
        <el-table-column
          align="center"
          prop="accountName"
          label="登录账号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="账号状态"
          width="80"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="success"
              >启用</el-tag
            >
            <el-tag v-else size="small" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="mobile" label="手机" width="150">
        </el-table-column>
        <el-table-column align="center" prop="" label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="openEditDialogNew(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="changeStatus(scope.row)"
              >{{ scope.row.status === 0 ? "禁用" : "启用" }}</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="resetPassWord(scope.row)"
              >重置密码</el-button
            >
            <el-button type="text" size="small" @click="deleteItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right; margin-top: 20px"
        layout="prev, pager, next, jumper"
        :page-size="formInline.pageSize"
        :current-page.sync="formInline.pageIndex"
        @current-change="handleCurrentChange"
        :total="formInline.totalElements"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="editDialogShow ? '编辑部门' : '新建部门'"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item required label="上级部门" prop="parentDepartmentId">
          <SelectTree
            :props="props"
            :options="data"
            :value="queryForm.parentDepartmentId"
            :clearable="isClearable"
            :accordion="isAccordion"
            @getValue="getValue($event)"
            height="200"
          ></SelectTree>
        </el-form-item>
        <el-form-item label="部门名称" required prop="departmentName">
          <el-input
            v-model="queryForm.departmentName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="pd20"
      :title="editDialogShowNew ? '编辑人员' : '新建人员'"
      :visible.sync="dialogFormVisibleNew"
    >
      <el-form :model="roleForm" status-icon ref="roleForm" :inline="true">
        <el-form-item label="昵称" required prop="nickName">
          <el-input v-model="roleForm.nickName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" required prop="realName">
          <el-input v-model="roleForm.realName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="登录账号"
          v-if="!editDialogShowNew"
          required
          prop="accountName"
        >
          <el-input
            v-model="roleForm.accountName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="登录密码"
          v-if="!editDialogShowNew"
          required
          prop="accountPassword"
        >
          <el-input
            v-model="roleForm.accountPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="roleForm.sex">
            <el-radio-button :label="1">男</el-radio-button>
            <el-radio-button :label="2">女</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属部门" required prop="departmentId">
          <SelectTree
            :props="props"
            :options="data"
            ref="department"
            :value="roleForm.departmentId"
            :clearable="isClearable"
            :accordion="isAccordion"
            @getValue="getValueNew($event)"
            height="200"
          ></SelectTree>
        </el-form-item>
        <el-form-item label="员工角色" required prop="roleId">
          <el-select v-model="roleForm.roleId" placeholder="请选择">
            <el-option
              v-for="item in roleOption"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" required prop="mobile">
          <el-input v-model="roleForm.mobile"></el-input> </el-form-item
        ><el-form-item label="电子邮箱" prop="mail">
          <el-input v-model="roleForm.mail"></el-input>
        </el-form-item>
        <el-form-item label="QQ号码" prop="qq">
          <el-input v-model="roleForm.qq"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew = false">取 消</el-button>
        <el-button
          v-if="editDialogShowNew"
          type="primary"
          @click="updateUnionNew"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnionNew">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SelectTree from "@/components/treeSelect.vue";
import { removeById } from "@/utils/util";
export default {
  components: { SelectTree },
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      roleOption: [],
      topId: "",
      dialogFormVisible: false,
      editDialogShow: false,
      dialogFormVisibleNew: false,
      editDialogShowNew: false,
      editUnionId: "",
      editRoleId: "",
      roleForm: {
        unionId: `${this.$route.query.unionId}`,
        nickName: "",
        realName: "",
        sex: 1,
        departmentId: "",
        roleId: "",
        accountName: "",
        accountPassword: "",
        mobile: "",
        mail: "",
        qq: "",
      },
      formInline: {
        nickName: "",
        departmentId: "",
        roleId: "",
        status: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryForm: {
        parentDepartmentId: "",
        departmentName: "",
        unionId: +this.$route.query.unionId,
      },
      editQueryForm: {
        parentDepartmentId: "",
        departmentName: "",
        unionId: +this.$route.query.unionId,
      },
      tableData: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "id",
        label: "label",
        children: "children",
      },
      filterText: "",
      data: [],
      editData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      lastDepartmentData: null,
    };
  },
  mounted() {
    this.getDepartment();
    this.getStaffList();
    this.getRoleList();
  },
  methods: {
    getRoleList() {
      this.$api.organizationApi
        .queryRoleList({
          unionId: +this.$route.query.unionId,
        })
        .then((res) => {
          if (res.code === 0) {
            this.roleOption = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.resetUnionPassword(pId);
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.deleteUnion(pId);
        })
        .catch(() => {});
    },
    resetUnionPassword(pId) {
      this.$api.organizationApi
        .resetMemberPassword({
          memberId: pId.id,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getStaffList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteMember({
          memberId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getStaffList();
          this.getDepartment();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeMemberStatus({
          memberId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getStaffList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getStaffList() {
      this.$api.organizationApi
        .queryMember({ ...this.formInline, unionId: this.queryForm.unionId })
        .then((res) => {
          const result = res.data.content ? res.data.content : [];
          this.tableData = result;
          this.formInline.totalElements = res.data.totalElements;
          this.formInline.pageIndex = res.data.pageIndex;
        });
    },
    handleNodeClick(data) {
      this.lastDepartmentData = {
        id: this.editUnionId,
        label: this.editQueryForm.departmentName,
        parentId: this.editQueryForm.parentDepartmentId,
      };
      this.refreshDepartmentData(data);
    },

    refreshDepartmentData(data) {
      this.editUnionId = data.id;
      this.queryForm.departmentName = data.label.split("(")[0];
      this.queryForm.parentDepartmentId = data.parentId;
      this.editQueryForm.departmentName = data.label.split("(")[0];
      this.editQueryForm.parentDepartmentId = data.parentId;
      this.formInline.departmentId = data.id;

      this.getStaffList();
    },
    openEditDialog() {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.queryForm = { ...this.editQueryForm };
      this.editData = this.arrayTreeAddLevel(this.data);
    },
    deleteLevel() {},
    arrayTreeAddLevel(array, levelName = "level", childrenName = "children") {
      if (!Array.isArray(array)) return [];
      const recursive = (array, level = 0) => {
        level++;
        return array.map((v) => {
          v[levelName] = level;
          const child = v[childrenName];
          if (child && child.length) recursive(child, level);
          return v;
        });
      };
      return recursive(array);
    },
    openEditDialogNew(data) {
      this.dialogFormVisibleNew = true;
      this.editDialogShowNew = true;
      this.editRoleId = data.id;
      setTimeout(() => {
        this.roleForm.nickName = data.nickName;
        this.roleForm.realName = data.realName;
        this.roleForm.sex = data.sex;
        this.roleForm.departmentId = data.departmentId;
        this.roleForm.roleId = data.roleId;
        this.roleForm.mobile = data.mobile;
        this.roleForm.qq = data.qq;
        this.roleForm.mail = data.mail;
      }, 1);
    },
    updateUnion() {
      this.$api.organizationApi
        .updateDepartment({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getDepartment();
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateUnionNew() {
      this.$api.organizationApi
        .updateMember({ ...this.roleForm, memberId: this.editRoleId })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getStaffList();
            this.dialogFormVisibleNew = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnionNew() {
      this.$api.organizationApi.createMember(this.roleForm).then((res) => {
        if (res.code === 0) {
          this.dialogFormVisibleNew = false;
          this.$message.success("操作成功!");
          this.getStaffList();
          this.getDepartment();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addUnion() {
      this.$api.organizationApi.createDepartment(this.queryForm).then((res) => {
        if (res.code === 0) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功!");
        } else {
          this.$message.error(res.msg);
        }
        this.getDepartment();
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    // 取值
    getValue(value) {
      this.queryForm.parentDepartmentId = value;
    },
    getValueNew(value) {
      this.roleForm.departmentId = value;
    },
    getDepartment() {
      this.$api.organizationApi
        .queryDepartment({
          unionId: +this.$route.query.unionId,
        })
        .then((res) => {
          const result = res.data ? res.data : {};
          this.topId = result.id;
          this.data = [];
          this.data.push(result);
        });
    },
    /* 清空选中样式 */
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getStaffList();
    },

    deleteDepartment() {
      this.$confirm("确定要删除部门吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        this.$api.organizationApi
          .deleteDepartment({
            unionId: +this.$route.query.unionId,
            id: this.formInline.departmentId,
          })
          .then(async (res) => {
            if (res.code !== 0) return this.$message.error(res.msg);
            this.data = removeById(this.data, this.formInline.departmentId);
            await this.refreshDepartmentData(this.lastDepartmentData);
            this.$message.success("删除成功");
            this.lastDepartmentData = null;
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  display: flex;
  background: #f3f3f3;
  .groupTree {
    width: 24%;
    max-width: 220px;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-right: 1%;
    overflow: hidden;
    overflow-x: auto;

    .btn1 {
      display: flex;
      .ant-btn {
        width: auto;
      }
      .ant-btn:last-child {
        margin-left: 9px;
      }
      margin-bottom: 10px;
    }
  }
  .grouplist {
    /deep/ .el-tree-node > .el-tree-node__children {
      overflow: initial;
    }
    strong {
      margin: 10px 0;
      display: block;
      font-size: 16px;
    }
  }
  .staffList {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    overflow-x: auto;
  }
}
</style>
