<template>
  <div class="unionList ground-white">
    <el-page-header @back="backHandle" />
    <br />
    <el-form :inline="true" :model="formInline">
      <el-form-item label="下级工会">
        <el-select
          v-model="formInline.unionId"
          clearable
          placeholder="请选择下级工会"
          @change="unionChange"
        >
          <el-option
            v-for="item in getUnionListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameServiceId" label="">
        <el-select
          v-model="formInline.gameServiceId"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.serviceId"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        label=""
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        sty="width: 220px"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          :type="formInline.dateType === '1' ? 'daterange' : 'monthrange'"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        角色创建数：<span>{{ totalData.roleCreateNum }}</span>
      </p>
      <p>
        角色活跃数：<span>{{ totalData.roleActiveNum }}</span>
      </p>
      <p>
        充值角色数：<span>{{ totalData.chargeRoleNum }}</span>
      </p>
      <p>
        充值总额：<span>{{ totalData.chargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="unionName" label="工会归属">
      </el-table-column>
      <el-table-column align="center" prop="accountName" label="推广员">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.accountName"
            :inviterAccountId="scope.row.accountLabel?.accountId"
            :departmentName="scope.row.accountLabel?.departmentName"
            :roleName="scope.row.accountLabel?.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏名称">
      </el-table-column>
      <el-table-column align="center" prop="gameServiceName" label="区服名">
      </el-table-column>
      <el-table-column align="center" prop="roleCreateNum" label="角色创建数">
      </el-table-column>
      <el-table-column align="center" prop="roleActiveNum" label="角色活跃数">
      </el-table-column>
      <el-table-column align="center" prop="chargeRoleNum" label="角色充值数">
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值总额">
      </el-table-column>
    </el-table>
    <Pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </Pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
import { CURDParams } from "@/utils/util";
const curdParams = new CURDParams("unionServices");
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts,
      },
      areaListData: [],
      getUnionListData: [],
      loading: false,
      formInline: {
        inviterAccountIdList: [],
        unionId: "",
        dateType: "1",
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
        gameServiceId: "",
        gameId: +this.$route.query.gameId,
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    this.getArea(this.$route.query.gameId);
    this.getUnionList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    backHandle() {
      this.$router.back();
      curdParams.setShow(true);
    },

    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    unionChange() {
      this.getAllInviter({ unionId: this.formInline.unionId });
      this.formInline.inviterAccountIdList = [];
    },
    getUnionList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getUnionListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    exportList() {
      this.formInline.pageSize = 50000;
      this.$api.dataApi
        .exportUnionStatis({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          this.formInline.pageSize = 20;
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      this.$api.dataApi
        .queryUnionTotalStatis({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      this.$api.dataApi
        .queryUnionStatisList({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            // this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
